import React from 'react';
import '../../styles/help.css'; // Ensure the path matches your folder structure
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Import the scroll enabler

const CalculationPuzzlesHelp = () => {
  return (
    <div className="help-container">
      {/* Enable scrolling on this page */}
      <EnableScrollOnPage />

      <h1>Calculation Puzzles</h1>

      <section className="intro-section">
        <p>This guide will walk you through how to interact with <a href="/calculation">calculation puzzles</a>, a unique and more challenging puzzle to learn.</p>
      </section>

      <section className="how-to-solve">
        <h2>What are Calculation Puzzles?</h2>
        <p>Calculation puzzles are the same as normal puzzles except you are looking at a position several moves before the actual puzzle occurs. If you haven't attempted a classic chess puzzle yet try some <a href="/puzzles" >here</a> first. When you start a calculation puzzle, you'll see the <strong>starting position</strong>. Let's look at an example:'</p>

        {/* Add any screenshot components or additional descriptions */}
        <div className="screenshot-container">
          <p><strong> Starting Position</strong></p>
          <img src={`${process.env.PUBLIC_URL}/help/startingPosition.png`} alt="Starting Position" className="screenshot" />
        </div>
        <p>Your task is to follow a sequence of moves in your head without moving the pieces, as is done when calculating in a real game. These moves are called <strong>leading moves</strong>, and are found next to the chessboard.</p>

        <div className="screenshot-container">
          <p><strong> Leading Moves</strong></p>
          <img src={`${process.env.PUBLIC_URL}/help/leadingMoves.png`} alt="Leading Moves" className="screenshot-small" />
        </div>

        <p>To increase or decrease the difficulty, you can change the number of leading moves by changing the <strong>depth setting</strong>. You can enter a depth of 1 to 8 moves back.</p>

        <div className="screenshot-container">
          <p><strong> Depth of Calculation Setting </strong></p>
          <img src={`${process.env.PUBLIC_URL}/help/depthSetting.png`} alt="Depth Setting" className="screenshot-small" />
        </div>

        <p>To make it easier, if you hover your mouse over the moves, the board will highlight the square the piece moved from (from square) and the square the piece moved to (to square). This will help you visualize the line more easily.</p>
        <div className="gif-container">
          <p><strong>Move Highlight on Hover</strong></p>
          <img src={`${process.env.PUBLIC_URL}/help/highlightMoves.gif`} alt="Move Highlight on Hover" className="gif-screenshot" />
        </div>

        <p>Following the leading moves will take you to the <strong>puzzle position</strong>. This position will never actually be displayed on the screen but with practice you be able to see it in your head. This is the position you need to evaluate. There is a clear winning move for you to find. Here is what the puzzle position should look like in your head. Do you see the best move?</p>

        <div className="screenshot-container">
          <p><strong>Puzzle Position</strong></p>
          <img src={`${process.env.PUBLIC_URL}/help/puzzlePosition.png`} alt="Puzzle Position" className="screenshot" />
        </div>

        <p>When you have determined your answer, you will input the move by selecting the from square of your move which will be highlighted in yellow. If you did not mean to select this square, click it again to deselect. Back to looking at the starting position, let's select a5 </p>

        <div className="screenshot-container">
          <p><strong>Highlighted From Square</strong></p>
          <img src={`${process.env.PUBLIC_URL}/help/fromSquare.png`} alt="From Square" className="screenshot" />
        </div>

        <p>Next click the to square of your move to submit your response. If your answer is correct the from square and to square will highlight green and either the computer will respond with a move and play continues or the puzzle is complete and you move on to the next one. Let's click c3 taking the Queen.</p>

        <div className="screenshot-container">
          <p><strong>Answered Correct</strong></p>
          <img src={`${process.env.PUBLIC_URL}/help/answeredCorrect.png`} alt="Answered Correct" className="screenshot" />
        </div>

        <p> If you entered an incorrect move, the from square and to square will highlight red and you can try again as many times as needed. Or you can hit the give up button if you can't seem to figure it out. Let's say we hit a5 and b4.</p>

        <div className="screenshot-container">
          <p><strong>Answered Incorrect</strong></p>
          <img src={`${process.env.PUBLIC_URL}/help/answeredIncorrect.png`} alt="Answered Incorrect" className="screenshot" />
        </div>


      </section>
      <section className="benefits-section">
        <h2>Why Are Calculation Puzzles Beneficial?</h2>
        <p>Calculation puzzles help improve your ability to deeply analyze positions in chess. They force you to visualize the board several moves ahead and anticipate your opponent's responses. The key benefits of calculation puzzles include:</p>
        <ul>
          <li><strong>Improved Visualization:</strong> You’ll train yourself to visualize sequences of moves without physically moving pieces on the board.</li>
          <li><strong>Improved Calculation:</strong> You'll learn to evaluate a position that hasn't been played on the board yet.</li>
          <li><strong>Pattern Recognition:</strong> As with solving any puzzle, you begin to recognize recurring tactical patterns and sequences.</li>
          <li><strong>Better Decision Making:</strong> You develop a habit of evaluating different candidate moves and responses, leading to more accurate decisions during your games.</li>
        </ul>
      </section>

      <section className="strategy-tips">
        <h2>Additional Questions?</h2>
        <p>What do the <a href="/ratingmetrics">rating metrics</a> mean and why is that special?</p>
      </section>

      <section className="further-help">
        <h2>Need More Help?</h2>
        <p>For further assistance or any questions, check the <a href="/help">main help page</a> or <a href="/contact">contact us</a> for additional resources.</p>
      </section>
    </div>
  );
};

export default CalculationPuzzlesHelp;
