import React, { createContext, useContext, useState, useEffect } from 'react';
import { themes } from './Themes'; // Make sure this path matches where your themes.js file is located

export const ThemesContext = createContext();
export const useTheme = () => useContext(ThemesContext);

export const ThemeProvider = ({ children }) => {
  // Try to load the theme name from localStorage, or default to 'tournament'
  const initialThemeName = localStorage.getItem('themeName') || 'royal';
  // Try to load custom theme settings from localStorage
  const savedCustomThemeSettings = localStorage.getItem('customThemeSettings');
  const initialCustomThemeSettings = savedCustomThemeSettings ? JSON.parse(savedCustomThemeSettings) : {};

  const [themeName, setThemeName] = useState(initialThemeName);
  const [customThemeSettings, setCustomThemeSettings] = useState(initialCustomThemeSettings);

  // Update the theme by name and reset custom settings (for predefined themes)
  const setTheme = (themeName) => {
    localStorage.setItem('themeName', themeName); // Save the theme name
    setThemeName(themeName);
    setCustomThemeSettings({}); // Reset custom settings when a predefined theme is selected
    localStorage.removeItem('customThemeSettings'); // Clear custom settings from localStorage
  };

  // Update custom theme settings and clear the predefined theme name
  const updateCustomTheme = (settings) => {
    setCustomThemeSettings(settings);
    localStorage.setItem('customThemeSettings', JSON.stringify(settings)); // Save custom settings
    console.log('Custom Theme settings: ' + JSON.stringify(settings))
    localStorage.removeItem('themeName'); // Remove the predefined theme name
    setThemeName(''); // Optionally clear themeName or set to a custom identifier
  };

  // Determine the current theme: custom settings take precedence
  const theme = Object.keys(customThemeSettings).length > 0 ? customThemeSettings : themes[themeName];

  return (
    <ThemesContext.Provider value={{ themeName, theme, setTheme, updateCustomTheme }}>
      {children}
    </ThemesContext.Provider>
  );
};
