import React, { useState } from 'react';

const SaveLineModal = ({ moves, onSave, onClose, directories, currentDirectory }) => {
  const [lineName, setLineName] = useState('');
  const [selectedDirectory, setSelectedDirectory] = useState(currentDirectory || ''); // Use currentDirectory as the default
  const [newDirectory, setNewDirectory] = useState('');

  const handleSave = () => {
    const directory = newDirectory || selectedDirectory;
    if (lineName && directory) {
      onSave(lineName, directory);
    }
  };

return (
  <div>
    <h2>Save Opening Line</h2>
    <input
      type="text"
      placeholder="Line Name"
      value={lineName}
      onChange={(e) => setLineName(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSave();
        }
      }}
      className="modal-input"
    />
    <div className="modal-buttons">
      <button onClick={handleSave} className="modal-button save">Save</button>
      <button onClick={onClose} className="modal-button cancel">Cancel</button>
    </div>
  </div>
);
};

export default SaveLineModal;
