import React, { useState, useEffect } from "react";
import { httpsCallable } from "firebase/functions";
import { db, app } from "../firebase-config";
import { useUser } from "../Integrations/UserContext";
import "../styles/manageSubscription.css"; // Create this CSS file for styling
import KnightHopLoadingScreen from '../Integrations/LoadingScreen';
import { collection, getDocs } from "firebase/firestore";
import { getCheckoutUrl, getPortalUrl, getPremiumStatus } from '../Integrations/GetCheckoutUrl';

const ManageSubscription = () => {
  const { user, isPremium, username } = useUser();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Function for fetching subscription details
    useEffect(() => {
      const fetchSubscriptionDetails = async () => {
        if (!user) return;

        try {
          // Correct way to query the subscriptions collection
          const subscriptionsRef = collection(db, "users", user.uid, "subscriptions");
          const subscriptionSnap = await getDocs(subscriptionsRef);

          if (!subscriptionSnap.empty) {
            // Assuming one active subscription, take the first document
            const subscriptionData = subscriptionSnap.docs[0].data();
            setSubscriptionDetails(subscriptionData);
          } else {
            setSubscriptionDetails(null);
          }
        } catch (error) {
          console.error("Error fetching subscription details:", error);
        }
      };

      fetchSubscriptionDetails();
    }, [user]);

  // Function for initiating a checkout session
  const handleSubscribe = async () => {
    if (!user) {
      alert("Please log in to subscribe.");
      return;
    }

    const checkoutData = {
        mode: "subscription",
        price: "price_1QLakLCWdD1hILCS2YFZinDR", // Replace with your subscription price ID
        success_url: window.location.origin + "/managesubscription",
        cancel_url: window.location.origin + "/managesubscription",
    };
    setIsLoading(true);
    try {
      const checkoutUrl = await getCheckoutUrl(app, checkoutData);
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error("Error initiating subscription checkout:", error);
      alert("Unable to start subscription checkout. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Function for opening the Stripe Customer Portal
  const handleManageSubscription = async () => {
    if (!user) return;
    setIsLoading(true);
    try {
      const url = await getPortalUrl(user);
      window.location.href = url;
    } catch (error) {
      console.error("Error opening Stripe customer portal:", error);
      alert("Unable to open customer portal. Please try again.");
    }
    setIsLoading(false);
  };

  return (
    <div className="manage-subscriptions">
      {isLoading && <KnightHopLoadingScreen />}
      <h1>Manage Your Subscription</h1>

      {/* Plan Comparison Table */}
      <table className="subscription-table">
        <thead>
          <tr>
            <th>Feature</th>
            <th>Basic</th>
            <th>Premium</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Access to Basic Training Tools</td>
            <td>✔️</td>
            <td>✔️</td>
          </tr>
          <tr>
            <td>Advanced Opening Trainer</td>
            <td>❌</td>
            <td>✔️</td>
          </tr>
          <tr>
            <td>Personalized Recommendations</td>
            <td>❌</td>
            <td>✔️</td>
          </tr>
          <tr>
            <td>Price</td>
            <td>Free</td>
            <td>$11.10/month</td>
          </tr>
        </tbody>
      </table>

      {/* Subscription Details */}
      {subscriptionDetails ? (
        <div className="subscription-status">
          <h2>Subscription Details</h2>
          <p>
            <strong>Status:</strong> {subscriptionDetails.status}
          </p>
          <p>
            <strong>Next Billing Date:</strong>{" "}
            {new Date(subscriptionDetails.current_period_end * 1000).toLocaleDateString()}
          </p>
          <button onClick={handleManageSubscription} className="manage-button">
            Manage Subscription
          </button>
        </div>
      ) : (
        <div className="subscription-status">
          <h2>Subscribe to Premium</h2>
          <p>Unlock all features by subscribing to the Premium plan.</p>
          <button onClick={handleSubscribe} className="subscribe-button" disabled={isLoading}>
            Subscribe Now
          </button>
        </div>
      )}
    <div>
      <h1>{isPremium ? "You have a Premium subscription!" : "You are not Premium"}</h1>
    </div>
    </div>
  );
};

export default ManageSubscription;
