import React from 'react';
import Chessboard from 'chessboardjsx';

const ChessboardPreview = ({ fen, playerColor, className, boardWidth = 200 }) => {
  return (
    <div className={className}>
      <div style={{ width: `${boardWidth}px`, height: `${boardWidth}px` }}>
        <Chessboard
          position={fen}
          orientation={playerColor}
          width={boardWidth} // Use the boardWidth prop
          id="previewBoard"
          draggable={false} // Make pieces non-draggable
          transitionDuration={0} // Remove piece transition animations
          boardStyle={{
            borderRadius: '5px',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.5)'
          }}
          squareStyles={{
            boxShadow: 'inset 0 0 1px 1px rgba(0, 0, 0, 0.8)'
          }}
          showNotation={false} // Hide notation for a cleaner look
          darkSquareStyle={{ backgroundColor: 'var(--dark-square-color)' }}
          lightSquareStyle={{ backgroundColor: 'var(--light-square-color)' }}
        />
      </div>
    </div>
  );
};

export default ChessboardPreview;
