import React, { Component } from 'react';

class GameOverModal extends Component {
  render() {
    const { isOpen, winner, onClose } = this.props;
    if (!isOpen) return null;

    return (
      <div className="modal-overlay">
        <div className="gameover-modal-content">
          <h2>Game Over</h2>
          <p>{winner}</p>
          <button className='btn-1' onClick={onClose}>Close</button>
        </div>
      </div>
    );
  }
}

export default GameOverModal;
