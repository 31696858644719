import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation

function NotFoundPage() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>404 Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <p>
        {/* Use Link to navigate back to the home page. Replace '/' with your application's home route if it's different */}
        <Link to="/">Go to Home Page</Link>
      </p>
    </div>
  );
}

export default NotFoundPage;
