import React from 'react';
import '../../styles/help.css'; // Ensure the path matches your folder structure
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Import the scroll enabler

const PuzzlesHelp = () => {
  return (
    <div className="help-container">
      {/* Enable scrolling on this page */}
      <EnableScrollOnPage />

      <h1>Puzzles</h1>

      <section className="what-are-puzzles">
        <h2>What Are Chess Puzzles?</h2>
        <p>Chess puzzles are scenarios derived from real games where there is a clear winning move or a series of moves. Your goal is to find the best move in the position.</p>
        <p>When you start a puzzle, you'll be presented with a position from a real game. The challenge is to find the best move to either win material, checkmate the opponent, or achieve a significant advantage.</p>
      </section>

      <section className="how-to-solve">
        <h2>How to Solve Puzzles</h2>
        <p>Once you are presented with the puzzle, carefully analyze the position. Your goal is to find the best move. Here's the process to solve a puzzle:</p>

        <h3>Step-by-Step Guide:</h3>
        <ul>
          <li><strong>Evaluate the Position:</strong> Look at all the available pieces, check for threats, opportunities, and potential tactics like forks, pins, or checkmates.</li>
          <li><strong>Find the Best Move:</strong> Your task is to find the best move. Sometimes it’s a checkmate in one move, while other times it might involve winning material over a few moves.</li>
          <li><strong>Input Your Move:</strong> Click the square of the piece you want to move (from square), and then click the destination square (to square).</li>
          <li><strong>Get Feedback:</strong> After inputting your move, you’ll receive immediate feedback. If the move is correct, you proceed to the next move in the puzzle or complete the puzzle.</li>
        </ul>

        <p>If your move is correct, the puzzle will continue, or you’ll complete the puzzle. If the move is wrong, you'll be given the opportunity to try again or see the solution.</p>
      </section>

      <section className="benefits-section">
        <h2>Why Solving Chess Puzzles is Important</h2>
        <p>Solving chess puzzles regularly offers multiple benefits:</p>
        <ul>
          <li><strong>Improved Pattern Recognition:</strong> Puzzles help you recognize common tactical patterns such as forks, pins, skewers, and mating nets.</li>
          <li><strong>Faster Calculation:</strong> Puzzles train your ability to calculate variations quickly and accurately, which is essential during real games.</li>
          <li><strong>Better Decision Making:</strong> By solving puzzles, you develop a habit of finding the best move in a position, which helps you make better decisions during your games.</li>
        </ul>
      </section>

      <section className="strategy-tips">
        <h2>Tips for Solving Puzzles</h2>
        <ul>
          <li><strong>Start with Easy Puzzles:</strong> Begin with simpler puzzles and gradually work your way up to more complex ones as you improve.</li>
          <li><strong>Look for Tactical Patterns:</strong> Keep an eye out for tactical opportunities like forks, skewers, pins, discovered attacks, and checkmates.</li>
          <li><strong>Calculate Carefully:</strong> Before making a move, visualize the opponent’s possible responses and ensure your move is the best one.</li>
          <li><strong>Be Patient:</strong> Don’t rush through puzzles. Take your time and make sure you’ve considered all possible moves.</li>
        </ul>
      </section>

      <section className="strategy-tips">
        <h2>Additional Questions?</h2>
        <p>What do the <a href="/ratingmetrics">rating metrics</a> mean and why is that special?</p>
      </section>

      <section className="further-help">
        <h2>Need More Help?</h2>
        <p>For more assistance, feel free to check the <a href="/help">main help page</a> or contact our support team for additional resources.</p>
      </section>
    </div>
  );
};

export default PuzzlesHelp;
