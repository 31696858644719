import React from 'react';
import { Chess } from "chess.js";

export const renderMoveHistory = (component, history, squareStyling) => {
  const MIN_ROWS = 5; // Minimum number of rows to display initially

const goToStartPosition = () => {
  if (component.setState) {
    // Class component case
    component.setState({
      displayFen: component.state.initialFen,
      selectedMoveIndex: -1,
    });
  } else if (component.state?.isMoveTrainer) {
    // Functional component (MoveTrainer) case
    component.setDisplayFen(component.state.initialFen);
    component.setSelectedMoveIndex(-1);
  } else if (component.setFen && typeof component.setFen === 'function') {
    component.setFen(component.state.initialFen);
    component.setSelectedMoveIndex(-1);
    component.setSquareStyles();
  } else {
    console.error("Component does not have valid state setters. Unable to update.");
  }
};


  const goToPreviousMove = () => {
    const selectedMoveIndex = component.state?.selectedMoveIndex ?? component.selectedMoveIndex;
    if (selectedMoveIndex <= 0) {
      if (selectedMoveIndex === 0) {
        goToStartPosition();
      }
    } else {
      const newMoveIndex = selectedMoveIndex - 1;
      goToMove(component, newMoveIndex, squareStyling);
    }
  };

  const goToNextMove = () => {
    const selectedMoveIndex = component.state?.selectedMoveIndex ?? component.selectedMoveIndex;
    const historyLength = component.state?.history.length ?? history.length;
    const newMoveIndex = Math.min(selectedMoveIndex + 1, historyLength - 1);
    goToMove(component, newMoveIndex, squareStyling);
  };

const goToMove = (component, moveIndex, squareStyling) => {
  const historyData = component.state?.history ?? component.history;
  const initialFen = component.state?.initialFen ?? component.initialFen;

  // Check if history is defined and is an array
  if (!historyData || !Array.isArray(historyData)) {
    console.error("History is undefined or not an array.");
    return;
  }

  const temporaryGame = new Chess(initialFen);

  // Apply the moves up to the given index
  historyData.slice(0, moveIndex + 1).forEach(move => {
//    console.log('attempting to play: ' + JSON.stringify(move));
    temporaryGame.move(move.san);
  });

  const tempFen = temporaryGame.fen();
  const updatedHistory = temporaryGame.history({ verbose: true });
  const newSquareStyles = squareStyling({ pieceSquare: "", history: updatedHistory });

  // Check if component is a functional component (e.g., BoardDisplay)
  if (component.setFen && typeof component.setFen === 'function') {
    console.log("Updating functional component (BoardDisplay). ", newSquareStyles);
    component.setFen(tempFen);
    component.setSquareStyles(newSquareStyles);
    component.setSelectedMoveIndex(moveIndex);
  }
  // Check if isMoveTrainer is part of component's state (for functional component MoveTrainer)
  else if (component.state?.isMoveTrainer === true) {
//    console.log("Updating functional component (MoveTrainer). Setting displayFen to:", tempFen);
//    console.log('new square styles: ' + JSON.stringify(newSquareStyles));
    component.setDisplayFen(tempFen);
    component.setSquareStyles(newSquareStyles);
    component.setSelectedMoveIndex(moveIndex);
    if (component.setIsViewingCurrentPosition) {
      component.setIsViewingCurrentPosition(false);
    }
  }
  // For class components (which use setState)
  else if (component.setState) {
    console.log("Updating class component. Setting displayFen to:", tempFen);
    component.setState({
      displayFen: tempFen,
      squareStyles: newSquareStyles,
      isViewingCurrentPosition: false,
      selectedMoveIndex: moveIndex,
    });
  } else {
    console.error("Component does not have valid state setters. Unable to update.");
  }
};

  const returnToCurrentPosition = (component, moveIndex) => {
    if (component.setState) {
      component.setState({
        displayFen: null,
        isViewingCurrentPosition: true,
        selectedMoveIndex: moveIndex,
      });
    } else {
      component.setFen(null);
      component.setIsViewingCurrentPosition(true);
      component.setSelectedMoveIndex(moveIndex);
    }
  };

  const copyFenToClipboard = () => {
    const fen = component.state?.fen ?? component.fen;
    navigator.clipboard.writeText(fen).then(() => {
      console.log('FEN copied to clipboard:', fen);
    }).catch(err => {
      console.error('Failed to copy FEN to clipboard:', err);
    });
  };

  // Create empty rows for placeholders if there are less than MIN_ROWS entries
  const rows = history.reduce((acc, move, index) => {
    if (index % 2 === 0) {
      acc.push([move]);
    } else {
      acc[acc.length - 1].push(move);
    }
    return acc;
  }, []);

  // Add empty rows to ensure at least MIN_ROWS are displayed
  while (rows.length < MIN_ROWS) {
    rows.push([]);
  }

  // Check if the component is MoveTrainer using the identifier passed in the state
  const isMoveTrainer = component.state?.isMoveTrainer ?? component.isMoveTrainer;
  const mode = component.state?.mode ?? component.mode;

return (
  <div className="move-history-container">
    <div className="history-controls">
      <button onClick={goToStartPosition}>
        <img src={process.env.PUBLIC_URL + "/buttons/backwardAll.png"} alt="Start Position" />
      </button>
      <button onClick={goToPreviousMove}>
        <img src={process.env.PUBLIC_URL + "/buttons/backward.png"} alt="Previous Move" />
      </button>
      <button onClick={goToNextMove}>
        <img src={process.env.PUBLIC_URL + "/buttons/forward.png"} alt="Next Move" />
      </button>
      <button onClick={() => goToMove(component, history.length - 1, squareStyling)}>
        <img src={process.env.PUBLIC_URL + "/buttons/forwardAll.png"} alt="Last Move" />
      </button>
    </div>

    <div className="move-history-scroll" ref={component.moveHistoryRef}>
      <table>
        <tbody>
          {rows.map((pair, pairIndex) => (
            <tr key={pairIndex}>
              <td className="move-number">{pairIndex + 1}</td>
              <td
                onClick={() => pair[0] && goToMove(component, pairIndex * 2, squareStyling)}
                className={`${component.state?.selectedMoveIndex === pairIndex * 2 || component.selectedMoveIndex === pairIndex * 2 ? 'selected ' : ''}move`}>
                {pair[0] ? pair[0].san : ""}
              </td>
              <td
                onClick={() => pair[1] && goToMove(component, pairIndex * 2 + 1, squareStyling)}
                className={`${pair[1] && (component.state?.selectedMoveIndex === pairIndex * 2 + 1 || component.selectedMoveIndex === pairIndex * 2 + 1) ? 'selected ' : ''}move`}>
                {pair[1] ? pair[1].san : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    {/* Conditional check for BoardDisplay to hide bottom buttons */}
    {!(component.setFen && typeof component.setFen === 'function') && (
      <div className="history-controls bottom-buttons">
        {isMoveTrainer ? (
          mode === 'addLine' || mode === 'edit' ? (
            <>
              <button onClick={component.handleTakeBackMove}>
                <img src={process.env.PUBLIC_URL + "/buttons/takeBack.png"} alt="Take Back Move" />
              </button>
              <button onClick={() => component.setState ? component.setState({ showSaveModal: true }) : component.setShowSaveModal(true)}>
                <img src={process.env.PUBLIC_URL + "/buttons/save.png"} alt="Save" />
              </button>
              <button onClick={component.openInLichess}>
                <img src={process.env.PUBLIC_URL + "/buttons/open.png"} alt="Open in Lichess" />
              </button>
            </>
          ) : (
            <>
            <button onClick={component.handleHint}>
              <img src={process.env.PUBLIC_URL + "/buttons/lightbulb.png"} alt="Hint" />
            </button>
              <button onClick={component.openInLichess}>
                <img src={process.env.PUBLIC_URL + "/buttons/open.png"} alt="Open in Lichess" />
              </button>
            </>
          )
        ) : (
          <>
            <button onClick={component.handleTakeBackMove}>
              <img src={process.env.PUBLIC_URL + "/buttons/takeBack.png"} alt="Take Back Move" />
            </button>
            <button onClick={component.handleHint}>
              <img src={process.env.PUBLIC_URL + "/buttons/lightbulb.png"} alt="Hint" />
            </button>
            <div className="share-button-container">
              <button ref={component.shareButtonRef} onClick={() => component.setState(prevState => ({ showShareOptions: !prevState.showShareOptions }))} className="toolbar-button">
                <img src={process.env.PUBLIC_URL + "/buttons/open.png"} alt="Open in Lichess" />
              </button>
              {component.state.showShareOptions && (
                <div ref={component.dropdownRef} className="share-options">
                  <button onClick={component.copyFenToClipboard} className="share-option">Copy FEN</button>
                  <button onClick={component.copyPgnToClipboard} className="share-option">Copy PGN</button>
                  <button onClick={component.openInLichess} className="share-option">Open in Lichess</button>
                </div>
              )}
            </div>
            <button onClick={component.handleResigns}>
              <img src={process.env.PUBLIC_URL + "/buttons/resigns.png"} alt="Resign" />
            </button>
          </>
        )}
      </div>
    )}
  </div>
);
};
