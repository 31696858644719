export const themes = {

royal: {
  '--primary-color': '#287F94', // Lighter deep sea blue, softened without transparency
  '--secondary-color': '#B0B9C0', // Slightly lighter soft silver gray
  '--highlight-color': '#E3B866', // Lighter soft salmon pink
  '--highlight-color-trans': '#ECC282', // Even lighter version of salmon pink
  '--highlight-color-dark': '#C19042', // Lighter dark salmon
  '--background-color': '#f8f8f8', // Slightly lighter off-white for the background
  '--text-color1': '#555555', // Lighter graphite gray for better readability
  '--text-color2': '#8A8A8A', // Lighter smoky gray for secondary text
  '--font': "'Montserrat', sans-serif", // Crisp and modern font, enhancing the elegant vibe
  '--dark-square-color': '#3B7688', // Lighter dark teal, maintaining elegance
  '--light-square-color': '#F2FBFF', // Very light sky blue, almost white, for an even softer visual contrast
  '--normal-piece': 'normal',
  '--marker-piece': 'marker',
},

  tournament: {
    '--primary-color': '#325300', // Corrected RGBA format
    '--secondary-color': '#9fa8b0',
    '--highlight-color': '#28a745',
    '--highlight-color-trans': 'rgba(40, 167, 69, 0.5)',
    '--highlight-color-dark': '#218838',
    '--background-color': '#f5f5f5',
    '--text-color1': '#2F4F4F', // Dark Slate Gray
    '--text-color2': 'black', // Midnight Blue
    '--font': "'Poppins', sans-serif", // Added fallback font
    '--dark-square-color': '#006400',
    '--light-square-color': '#FFFFE0',
    '--normal-piece': 'normal',
    '--marker-piece': 'marker',
  },

    brightAndLight: {
      '--primary-color': '#6a8faf', // Muted blue
      '--secondary-color': '#a8b6c1', // Soft grey-blue, for a gentle contrast
      '--highlight-color': '#ff6b6b', // Bright coral, for vibrant highlights
      '--highlight-color-trans': 'rgba(255, 107, 107, 0.5)', // Translucent bright coral
      '--highlight-color-dark': '#cc5656', // Darker shade of the highlight for depth
      '--background-color': '#f0f0f0', // Off-white, for a softer background than pure white
      '--text-color1': '#333333', // Almost black, maintaining contrast for readability
      '--text-color2': '#555555', // Dark gray, slightly softer for secondary text
      '--font': "'Roboto', sans-serif", // Retaining the modern, geometric sans-serif for its versatility
      '--dark-square-color': '#495d7a', // Deep slate blue
      '--light-square-color': '#e3e6e8', // Subtle, warm gray
      '--normal-piece': 'normal',
      '--marker-piece': 'marker-bal',
    },

    darkAndMoody: {
      '--primary-color': '#4a3423', // Very dark orange
      '--secondary-color': '#424248', // Almost black
      '--highlight-color': '#ef8354', // Warm terracotta
      '--highlight-color-trans': 'rgba(239, 131, 84, 0.8)', // Translucent warm terracotta
      '--highlight-color-dark': '#b33030', // Deep red
      '--background-color': '#1a1a1d', // Near black
      '--text-color1': '#cccccc', // Light gray
      '--text-color2': '#ffffff', // Pure white
      '--font': "'Merriweather', serif", // Elegant, highly readable serif
      '--dark-square-color': '#4a3423', // Dark wood, complementing the very dark orange and adding depth
      '--light-square-color': '#d7c4b1', // Light sand, providing a subtle contrast and warmth
      '--normal-piece': 'normal-modern',
      '--marker-piece': 'marker-dam',
    },

    freshAndNatural: {
      '--primary-color': '#69B088', // Soft sea green
      '--secondary-color': '#adc6ba', // Soft mint
      '--highlight-color': '#f8b595', // Soft coral
      '--highlight-color-trans': 'rgba(248, 181, 149, 0.5)', // Translucent soft coral
      '--highlight-color-dark': '#dfa286', // darker coral
      '--background-color': '#fefefc', // Creamy off-white
      '--text-color1': '#3c3c3c', // Dark gray
      '--text-color2': '#5a5a5a', // Medium gray
      '--font': "'Open Sans', sans-serif", // Humanist sans-serif with great legibility
      '--dark-square-color': '#3E7547', // Muted seaweed green
      '--light-square-color': '#f0ede5', // Very light beige
      '--normal-piece': 'normal',
      '--marker-piece': 'marker-tree',
    },

    classical: {
      '--primary-color': '#7b5e3b', // Medium wood brown, reminiscent of light chess pieces
      '--secondary-color': '#c3baac', // Dark wood brown, evoking the essence of dark chess pieces
      '--highlight-color': '#b89b72', // Creamy beige, similar to the lighter squares of a chessboard
      '--highlight-color-trans': 'rgba(244, 233, 216, 0.5)', // Translucent creamy beige for overlay effects
      '--highlight-color-dark': '#563e2c', // Rich, dark brown, providing depth and contrast
      '--background-color': '#f0e7d4', // Warm, light cream, setting a subtle, sophisticated backdrop
      '--text-color1': '#3c2f2f', // Deep, dark brown for high readability against lighter backgrounds
      '--text-color2': '#5d5345', // Slightly lighter brown, offering a soft contrast for secondary text
      '--font': "'Playfair Display', serif", // Classic, elegant serif font for a touch of sophistication
      '--light-square-color': '#f4e9d8', // Matching the highlight color, for the light squares of the chessboard
      '--dark-square-color': '#7b5e3b', // Echoing the secondary color, for the dark squares of the chessboard
      '--normal-piece': 'normal',
      '--marker-piece': 'marker',
    }


};
