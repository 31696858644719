import React, { useState, useEffect } from 'react';
import BoardDisplay from './BoardDisplay';

const MatchDetail = ({ matchUrl }) => {
  const [matchDetail, setMatchDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null); // To track the selected board for ongoing games

  useEffect(() => {
    const fetchMatchDetail = async () => {
      try {
        const response = await fetch(matchUrl);
        const data = await response.json();
        setMatchDetail(data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch match details');
        setLoading(false);
      }
    };

    if (matchUrl) {
      fetchMatchDetail();
    }
  }, [matchUrl]);

  // Define onClose to reset the selected board
  const onClose = () => {
    setSelectedBoard(null); // Close the board by setting it to null
  };

  const handleBoardClick = (boardUrl, username) => {
    setSelectedBoard({ boardUrl, username });  // Track the board URL and username
  };

  if (loading) {
    return <div>Loading match details...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!matchDetail) {
    return <div>No match details available</div>;
  }

  // Check if "Latter-day Saint Chess Friends" is team1 or team2
  const isOurTeam = matchDetail.teams.team1.name === "The Commoner's Guild";
  const ourTeam = isOurTeam ? matchDetail.teams.team1 : matchDetail.teams.team2;
  const opponentTeam = isOurTeam ? matchDetail.teams.team2 : matchDetail.teams.team1;

  // Calculate total points for both teams with one decimal point
const calculateScore = (player) => {
  const whiteResult = player.played_as_white === 'win' ? 1 : (player.played_as_white === 'agreed' || player.played_as_white === 'stalemate') ? 0.5 : 0;
  const blackResult = player.played_as_black === 'win' ? 1 : (player.played_as_black === 'agreed' || player.played_as_black === 'stalemate') ? 0.5 : 0;
  return (whiteResult + blackResult).toFixed(1);
};


  const ourTeamPoints = ourTeam.players.reduce((total, player) => total + parseFloat(calculateScore(player)), 0).toFixed(1);
  const opponentTeamPoints = opponentTeam.players.reduce((total, player) => total + parseFloat(calculateScore(player)), 0).toFixed(1);

  // Identify players who won both their games (MVPs)
  const MVPs = ourTeam.players.filter(
    player => parseFloat(calculateScore(player)) === 2
  );

  // Identify ongoing and finished games
  const ongoingGames = ourTeam.players.filter(player => !player.played_as_white || !player.played_as_black);
  const finishedGames = ourTeam.players.filter(player => player.played_as_white && player.played_as_black);

return (
  <div className="match-detail-container" onClick={(e) => e.stopPropagation()}>
    <h2>{matchDetail.name}</h2>

    {/* Team Scoreboard */}
    <div className="scoreboard">
      <div className="team-score">
        <strong>{ourTeam.name}</strong> <span>{ourTeamPoints}</span>
      </div>
      <div className="team-score">
        <strong>{opponentTeam.name}</strong> <span>{opponentTeamPoints}</span>
      </div>
    </div>

    {/* Congratulate MVPs */}
    {MVPs.length > 0 && (
      <div className="mvp-section">
        <h3>Match MVP(s) who won both games!:</h3>
        <p>{MVPs.map(player => player.username).join(', ')}</p>
      </div>
    )}

    <h3>Ongoing Games</h3>
    <ul className="games-list">
      {ongoingGames.length > 0 ? (
        ongoingGames.map((player, index) => (
          <li key={index}>
            <button
              className="player-button"
              onClick={(e) => {
                e.stopPropagation();  // Prevent event bubbling
                handleBoardClick(player.board, player.username);
              }}
            >
              {player.username}
            </button>
          </li>
        ))
      ) : (
        <p>No ongoing games.</p>
      )}
    </ul>

    <h3>Finished Games</h3>
    <ul className="games-list">
      {finishedGames.length > 0 ? (
        finishedGames.map((player, index) => (
          <li key={index}>
            <button
              className="player-button"
              onClick={(e) => {
                e.stopPropagation();  // Prevent event bubbling
                handleBoardClick(player.board, player.username);
              }}
            >
              {player.username}
            </button>
            - Score: {calculateScore(player)}
          </li>
        ))
      ) : (
        <p>No finished games.</p>
      )}
    </ul>

    {/* Render the Board component if a board is selected */}
    {selectedBoard && (
      <BoardDisplay
        boardUrl={selectedBoard.boardUrl}
        userTeamPlayer={selectedBoard.username}
        onClose={onClose}  // Pass the onClose function
      />
    )}
  </div>
);
};

export default MatchDetail;
