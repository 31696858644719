import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Chess } from 'chess.js';
import '../styles/QuizComponent.css';
import '../styles/styles.css';
import '../styles/chessboard.css';

const QuizComponent = ({ fen, playerColor, chessboardSize, updateShowQuiz, quizAnswer, request}) => {
  const canvasRef = useRef(null);
  const [isRequest, setIsRequest] = useState(request ? true : false);
  const [isQuizContainerCollapsed, setIsQuizContainerCollapsed] = useState(false);
  const [userAnswer, setUserAnswer] = useState('');
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [solutions, setSolutions] = useState({ playerSolutions: [], opponentSolutions: [] });
  const [arrowSolutions, setArrowSolutions] = useState({ playerArrows: [], opponentArrows: [] });
  const [shouldShowQuiz, setShouldShowQuiz] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const opponentColor = playerColor === 'white' ? 'black' : 'white';
  const [dragStart, setDragStart] = useState(null);
  const [dragEnd, setDragEnd] = useState(null);
  const [keyStates, setKeyStates] = useState({ shift: false, ctrl: false, alt: false });
  const isRightMouseDownRef = useRef(false);
  const [drawnArrows, setDrawnArrows] = useState([]);
  const [game, setGame] = useState(new Chess());
  const [lastProcessedAnswer, setLastProcessedAnswer] = useState(null);
  const dragEndRef = useRef(null);
  const dragStartRef = useRef(null);
  const gameRef = useRef(new Chess());
  const solutionsRef = useRef(null);
  const fenRef = useRef(null);

  useEffect(() => {
    setGame(new Chess(fen));
    gameRef.current.load(fen);
    fenRef.current = fen;
//    console.log('Use Effect FEN: ' + fen)
  }, [fen]);

const toggleQuizContainer = () => {
  setIsQuizContainerCollapsed(!isQuizContainerCollapsed);
};


// Positive feedback messages
const positiveFeedbackMessages = [
  'Correct!',
  'Yes, that’s right!',
  'Keep going!',
  'Excellent work!',
  'You got it!',
  'Spot on!',
  'That’s the spirit!',
];

// Negative feedback messages
const negativeFeedbackMessages = [
  'Incorrect.',
  'Try again.',
  'Not quite.',
  'Almost, but not quite.',
  'Give it another shot.',
  'Think it over once more.',
  'Close, but not exactly.',
];

const overlayRef = useRef(null);

const flashCanvasRed = () => {
  if (overlayRef.current) {
    overlayRef.current.style.display = 'block'; // Show the overlay

    setTimeout(() => {
      overlayRef.current.style.display = 'none'; // Hide the overlay after 1 second
    }, 1000);
  }
};

// Function to get a random positive feedback message
function getRandomPositiveFeedback() {
  const randomIndex = Math.floor(Math.random() * positiveFeedbackMessages.length);
  return positiveFeedbackMessages[randomIndex];
}

// Function to get a random negative feedback message
function getRandomNegativeFeedback() {
  const randomIndex = Math.floor(Math.random() * negativeFeedbackMessages.length);
  return negativeFeedbackMessages[randomIndex];
}

const markSolutionAsCorrect = (move) => {
  setCorrectAnswers((prevCorrectAnswers) => {
    // Check if the move is not already included and add it
    if (!prevCorrectAnswers.includes(move)) {
      const updatedCorrectAnswers = [...prevCorrectAnswers, move];
      // Check if all solutions have been found after updating
      if (updatedCorrectAnswers.length === solutions.playerSolutions.length + solutions.opponentSolutions.length) {
        // If this condition is met, the quiz is completed
        // However, we can't directly set other state variables here
        // We'll handle the quiz completion logic outside
        return updatedCorrectAnswers;
      }
      return updatedCorrectAnswers;
    }
    return prevCorrectAnswers; // Return the existing array if the move is already marked as correct
  });
};



  // Utility function to get the color of the piece at a given square
  const getPieceColorAtSquare = (game, square) => {
    console.log('Square: ' + square + 'game.getsquare ' + game.get(square) + ' game: ' + JSON.stringify(game))
    const piece = game.get(square);
    if (piece) {
      return piece.color; // 'w' or 'b'
    } else {
      return null;
    }
  };

const closeQuizMessage = () => {
  setShouldShowQuiz(false);
};

// Arrow Stuff

  const clearArrows = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

const setupCanvas = () => {
  const canvas = canvasRef.current;

  // Using the latest chessboard size passed from the parent component
  const size = chessboardSize;

  if (canvas) {
    canvas.width = size; // Directly manipulating canvas size
    canvas.height = size;
    canvas.style.width = `${size}px`; // Adjusting style for visual size
    canvas.style.height = `${size}px`;

    // Accessing the canvas context to draw or clear it
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas for new drawing
    ctx.fillStyle = 'rgba(50, 50, 50, 0.1)'; // Setting a fill style for demonstration
    ctx.fillRect(0, 0, canvas.width, canvas.height); // Filling the canvas with the chosen style
  } else {
//    console.log('Canvas not found');
  }
};

const removeCanvas = () => {
  const canvas = canvasRef.current;
  if (canvas && canvas.parentNode) {
    canvas.parentNode.removeChild(canvas);
  }
};

const drawArrow = (fromSquare, toSquare, pieceColor) => {
  const canvas = canvasRef.current;
  if (canvas && fromSquare && toSquare) {
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    let color = pieceColor === 'w' ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)';

    const canvasSize = canvas.width;
    const squareSize = canvasSize / 8;

    const fromCoords = squareToCanvasCoords(fromSquare, squareSize);
    const toCoords = squareToCanvasCoords(toSquare, squareSize);
    if (!fromCoords || !toCoords) return;

    if (fromSquare === toSquare) {
      const circleRadius = squareSize / 4; // Reduce the circle radius for same-square highlighting
      ctx.beginPath();
      ctx.arc(fromCoords.x, fromCoords.y, circleRadius, 0, 2 * Math.PI);
      ctx.strokeStyle = color;
      ctx.lineWidth = squareSize / 15; // Make the line thinner
      ctx.stroke();
      return;
    }

    // Reduce the head length for smaller arrowheads
    const headlen = squareSize / 3; // Decrease for smaller arrow head
    const angle = Math.atan2(toCoords.y - fromCoords.y, toCoords.x - fromCoords.x);
    const offset = headlen * 0.75; // Adjust offset to fit smaller headlen
    const stemEndX = toCoords.x - offset * Math.cos(angle);
    const stemEndY = toCoords.y - offset * Math.sin(angle);

    // Make the stem thinner
    ctx.beginPath();
    ctx.moveTo(fromCoords.x, fromCoords.y);
    ctx.lineTo(stemEndX, stemEndY);
    ctx.strokeStyle = color;
    ctx.lineWidth = squareSize / 8; // Decrease for thinner stem
    ctx.stroke();

    // Draw the smaller arrow head
    ctx.beginPath();
    ctx.moveTo(toCoords.x, toCoords.y);
    ctx.lineTo(toCoords.x - headlen * Math.cos(angle - Math.PI / 6), toCoords.y - headlen * Math.sin(angle - Math.PI / 6));
    ctx.lineTo(toCoords.x - headlen * Math.cos(angle + Math.PI / 6), toCoords.y - headlen * Math.sin(angle + Math.PI / 6));
    ctx.closePath();
    ctx.fillStyle = color;
    ctx.fill();

    const newArrow = { fromSquare, toSquare };
    setDrawnArrows(prevArrows => [...prevArrows, newArrow]);
  }
};




const squareToCanvasCoords = (square, squareSize) => {
  let file = square.charCodeAt(0) - 'a'.charCodeAt(0); // Convert file letter (e.g., 'e') to index (0 to 7)
  let rank = 8 - (square[1] - '0');
  // Assuming playerColor is accessible from the component's state or context
  if (playerColor === 'black') {
    rank = 7 - rank; // Invert rank index for black player orientation
    file = 7 - file;
  }
  // Compute the center coordinates of the square on the canvas
  return {
    x: file * squareSize + squareSize / 2, // Center of the square horizontally
    y: rank * squareSize + squareSize / 2, // Center of the square vertically
  };
}



const getSquareFromCoordinates = (x, y) => {
  if (!canvasRef.current) {
//    console.log('Canvas is not available');
    return; // Exit the function if canvas is not available
  }

  const rect = canvasRef.current.getBoundingClientRect();
  const scale = {
    x: canvasRef.current.width / rect.width,
    y: canvasRef.current.height / rect.height,
  };
  const adjustedX = (x - rect.left) * scale.x;
  const adjustedY = (y - rect.top) * scale.y;

  const squareSize = canvasRef.current.width / 8;
  let file = Math.floor(adjustedX / squareSize);
  let rank = 7 - Math.floor(adjustedY / squareSize);
  if (playerColor === 'black') {
    file = 7 - file; // Flip file index for black orientation
    rank = 7 - rank; // Flip rank index for black orientation
  }
  const fileLetter = String.fromCharCode('a'.charCodeAt(0) + file);
  const rankNumber = rank + 1;
  return `${fileLetter}${rankNumber}`;
};


useEffect(() => {
    const canvas = canvasRef.current;
//    console.log('canvas: ' + canvas);

    // Prevent the default context menu on right-click.
    const handleContextMenu = (event) => {
        event.preventDefault();
//        console.log('default prevented');
    };

    let tempDragStart = null;

    // Handle right mouse button press.
    const handleMouseDown = (event) => {
        if (isRightMouseDownRef.current !== null) {
            isRightMouseDownRef.current = true;
            const fromSquare = getSquareFromCoordinates(event.clientX, event.clientY);
            tempDragStart = fromSquare;
            setDragStart(fromSquare);
        }
    };

    // Handle mouse move, if right mouse button is pressed.
    const handleMouseMove = (event) => {
        if (isRightMouseDownRef.current) {
//            console.log('right click and drag');
            const toSquare = getSquareFromCoordinates(event.clientX, event.clientY);
            setDragEnd(toSquare);
        }
    };

const handleMouseUp = (event) => {
  // Check if the event target is the canvas to ensure feedback is only for canvas clicks
  if ((event.button === 2 || event.button === 0) && isRightMouseDownRef.current !== null) {
    const toSquare = getSquareFromCoordinates(event.clientX, event.clientY);
    const fromSquare = tempDragStart;
    isRightMouseDownRef.current = false;
    // Reset the drag states regardless of the move's correctness
    setDragStart(null);
    setDragEnd(null);

    const pieceColor = getPieceColorAtSquare(gameRef.current, fromSquare);
    // If there's no piece at fromSquare or if fromSquare is not defined, exit the function
    if (!fromSquare || pieceColor === null) {
      return;
    }

    const perspectiveFEN = adjustFENForPerspective(game.fen(), pieceColor);
    const moveInAlgebraic = convertToAlgebraicNotation(fromSquare, toSquare, perspectiveFEN);
    // Assuming solutions state holds both player and opponent solutions
    const isCorrectMove = checkMoveAgainstSolutions(moveInAlgebraic, perspectiveFEN, solutions);
    const isValidSquare = square => /^[a-h][1-8]$/.test(square);
    if (isCorrectMove) {
      markSolutionAsCorrect(moveInAlgebraic);
      drawArrow(fromSquare, toSquare, pieceColor); // Ensure drawArrow can accept a color parameter
      setFeedback(getRandomPositiveFeedback());
    } else if (!isCorrectMove && isValidSquare(fromSquare) && isValidSquare(toSquare)) {
      setFeedback(getRandomNegativeFeedback());
      flashCanvasRed();
      // Optionally, do not draw the arrow or draw it in a different style to indicate incorrectness
    }
  }
};

const handleStart = (event) => {
  // Get the first touch point
  const touch = event.touches[0];
  const clientX = touch.clientX;
  const clientY = touch.clientY;

  // Assuming you have a way to determine if the touch should start a drag
  isRightMouseDownRef.current = true;
  const fromSquare = getSquareFromCoordinates(clientX, clientY);
  setDragStart(fromSquare); // Setting start position for the drag
  dragStartRef.current = fromSquare;
  event.preventDefault(); // Prevent default to avoid scrolling and other touch behaviors
};

const handleMove = (event) => {
  if (isRightMouseDownRef.current && event.touches && event.touches.length > 0) {
    const touch = event.touches[0];
    const toSquare = getSquareFromCoordinates(touch.clientX, touch.clientY);
    if (toSquare) {
      setDragEnd(toSquare); // Continue to use setState for re-render if needed
      dragEndRef.current = toSquare; // Also update the ref for immediate access
    } else {
      console.error('Failed to calculate toSquare:', touch.clientX, touch.clientY);
    }
  }
};

const handleEnd = (event) => {
  if (isRightMouseDownRef.current) {
    isRightMouseDownRef.current = false;
    const fromSquare = dragStartRef.current;
    const toSquare = dragEndRef.current;  // Now using the ref
    if (fromSquare && toSquare) { // Ensure start and end are set
          console.log('from Square: ' + fromSquare)
          const pieceColor = getPieceColorAtSquare(gameRef.current,fromSquare);
          console.log('Piece color: ' + pieceColor)
    // If there's no piece at fromSquare or if fromSquare is not defined, exit the function
    if (!fromSquare || pieceColor === null) {
      return;
    }

    const perspectiveFEN = adjustFENForPerspective(fenRef.current, pieceColor);
    const moveInAlgebraic = convertToAlgebraicNotation(fromSquare, toSquare, perspectiveFEN);
    // Assuming solutions state holds both player and opponent solutions
    const isCorrectMove = checkMoveAgainstSolutions(moveInAlgebraic, perspectiveFEN, solutionsRef.current);
    const isValidSquare = square => /^[a-h][1-8]$/.test(square);
    if (isCorrectMove) {
      markSolutionAsCorrect(moveInAlgebraic);
      drawArrow(fromSquare, toSquare, pieceColor); // Ensure drawArrow can accept a color parameter
      setFeedback(getRandomPositiveFeedback());
    } else if (!isCorrectMove && isValidSquare(fromSquare) && isValidSquare(toSquare)) {
      setFeedback(getRandomNegativeFeedback());
      flashCanvasRed();
    }
    }
  }
};


function adjustFENForPerspective(fen, pieceColor) {
  // Ensure the FEN is split correctly and all parts are present
  const parts = fen.split(' ');
  if (parts.length !== 6) {
    console.error('Invalid FEN: ', fen);
    return fen; // Return the original FEN to avoid further errors
  }

  // Directly set the turn indicator to match the pieceColor
  parts[1] = pieceColor;

  return parts.join(' ');
}


function convertToAlgebraicNotation(fromSquare, toSquare, fen) {
  console.log('Convert to algebraic FEN: ' + fen)
  const game = new Chess(fen);

  // Generate all legal moves in verbose mode to access SAN notation
  const legalMoves = game.moves({ verbose: true });

  // Construct a move in Standard Algebraic Notation (SAN) for comparison
  // This assumes you have a way to convert 'fromSquare' and 'toSquare' to SAN
  // For simplicity, let's directly use 'fromSquare' and 'toSquare' here
  // You would replace this with actual conversion to SAN if necessary
  const userMoveSAN = `${fromSquare}${toSquare}`;

  // Check if the user's move (in SAN) is within the list of legal moves
  const isLegalMove = legalMoves.some(move => move.from === fromSquare && move.to === toSquare);

  if (isLegalMove) {
    // Find the move object that matches the user's move
    const move = legalMoves.find(move => move.from === fromSquare && move.to === toSquare);

    // Execute the move using its SAN notation if promotion is needed
    let result;
    if (needsPromotion(fromSquare, toSquare)) {
      result = game.move({ from: move.from, to: move.to, promotion: 'q' }, { sloppy: true });
    } else {
      result = game.move(move.san, { sloppy: true });
    }

    if (result) {
      // Return the move in Standard Algebraic Notation (SAN)
      return result.san;
    } else {
//      console.error("Unexpected error: Failed to execute a legal move.");
      return null; // Move execution failed, return null
    }
  } else {
//    console.log("Illegal move attempted:", userMoveSAN);
    return null; // Illegal move, return null
  }
}


function needsPromotion(from, to) {
  // Example condition for promotion: a pawn reaches the 8th rank
  // You'll need to adjust the logic based on actual game state and rules
  const rank = to[1];
  const file = from[0];
  const piece = game.get(from);
  return piece && piece.type === 'p' && (rank === '8' || rank === '1');
}

function checkMoveAgainstSolutions(moveInAlgebraic, perspectiveFEN, combinedSolutions) {
//  console.log('perspectiveFEN:', perspectiveFEN, 'Type:', typeof perspectiveFEN);

  // Determine whose turn it is from the perspective FEN
  const turn = perspectiveFEN.split(' ')[1];

  // Depending on the turn, check the move against the appropriate set of solutions
  const correctSolutions = turn === playerColor[0] ? combinedSolutions.playerSolutions : combinedSolutions.opponentSolutions;
  console.log('move in algebraic: ' + moveInAlgebraic)
  console.log('persepctiveFEN' + perspectiveFEN)
  console.log('correct solutions: ' + correctSolutions)
  console.log('return' + correctSolutions.includes(moveInAlgebraic))
  // Check if the move is in the set of correct solutions
  return correctSolutions.includes(moveInAlgebraic);
}


    if (canvas) {
        canvas.addEventListener('contextmenu', handleContextMenu);
        canvas.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        canvas.addEventListener('touchstart', handleStart);
        document.addEventListener('touchmove', handleMove);
        document.addEventListener('touchend', handleEnd);

    }

    return () => {
        if (canvas) {
            canvas.removeEventListener('contextmenu', handleContextMenu);
            canvas.removeEventListener('mousedown', handleMouseDown);
            canvas.removeEventListener('touchdown', handleStart);
        }
        // Cleanup needs to remove document level event listeners too.
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('touchmove', handleMove);
        document.removeEventListener('touchup', handleEnd);
    };
}, [shouldShowQuiz, game]); // Deps array to re-bind if needed, though you might adjust based on actual needs.


  //End arrow stuff


  // Utility function to check if it's the player's turn based on the FEN
  const isPlayersTurn = (fen, playerColor) => {
  const turnIndicator = fen.split(' ')[1];
  return (
    (playerColor === 'white' && turnIndicator === 'w') ||
    (playerColor === 'black' && turnIndicator === 'b')
  );
};

// Utility function to generate solutions
const generateSolutions = (game, playerColor) => {
  // Determine if it's currently the player's turn
  const playersTurn = isPlayersTurn(game.fen(), playerColor);

  const moves = game.moves({ verbose: true });
  const checksAndCaptures = moves.filter(move => move.flags.includes('c') || move.flags.includes('e') || move.san.includes('+') || move.san.includes('#'));
  const solutions = checksAndCaptures.map(move => move.san);
  console.log('Moves ' + moves + ' Checks and capts: ' + checksAndCaptures + ' solutions: ' + solutions)
  return {
    isPlayersTurn: playersTurn,
    solutions,
  };
};

// Utility function to generate solutions with from, to, and color
const generateArrows = (game, playerColor) => {
  const moves = game.moves({ verbose: true });
  // Filter for moves that are checks, captures, etc., as before
  const solutions = moves.filter(move => move.flags.includes('c') || move.flags.includes('e') || move.san.includes('+') || move.san.includes('#'))
    .map(move => ({
      from: move.from,
      to: move.to,
      color: move.color // 'w' or 'b'
    }));

  return solutions;
};


// Revised useEffect for generating solutions
useEffect(() => {
  setUserAnswer('');
  setFeedback('');
  setCorrectAnswers([]);
  setIsQuizCompleted(false);
  console.log('All inputs: ' + fen + playerColor + chessboardSize + updateShowQuiz + quizAnswer )
  if (fen && fen.split(' ').length === 6) {
    const game = new Chess(fen);
    console.log('Use Effect2 FEN: ' + fen)
    if (game.inCheck()) {
      return;
    }

    let { isPlayersTurn, solutions: playerSolutions } = generateSolutions(game, playerColor);
    let playerArrows = generateArrows(game, playerColor);

    // Switch to opponent's turn to generate opponent's solutions
    const fenParts = game.fen().split(' ');
    fenParts[1] = fenParts[1] === 'w' ? 'b' : 'w';
    game.load(fenParts.join(' '));

    let { solutions: opponentSolutions } = generateSolutions(game, playerColor);
    let opponentArrows = generateArrows(game, playerColor)
    // If it's not the player's turn or if there are no solutions, exit
    if (!isPlayersTurn || (playerSolutions.length === 0 && opponentSolutions.length === 0)) {
      return;
    }

    setSolutions({ playerSolutions, opponentSolutions });
    solutionsRef.current = { playerSolutions, opponentSolutions };
    setArrowSolutions({ playerArrows, opponentArrows });

    const moveNumber = parseInt(fenParts[5], 10);
    const showQuiz = moveNumber > 5 && Math.random() < .25 || isRequest;
//    const showQuiz = true;
    setShouldShowQuiz(showQuiz);
    updateShowQuiz(showQuiz);
  }
}, [fen, playerColor]);

useEffect(() => {
  // Adjusts the game's FEN for the current perspective
  if (quizAnswer === lastProcessedAnswer) return;
  const adjustFENForPerspective = (fen, playerColor) => {
    const parts = fen.split(' ');
    if (parts.length !== 6) {
      console.error('Invalid FEN:', fen);
      return fen;
    }
    // This part does not need to change because we always want to work with the current game state
    return fen;
  };

  // Checks if the quiz answer is correct based on solutions available for both player and opponent
  const checkAnswer = (answer, solutions) => {
    return solutions.playerSolutions.includes(answer) || solutions.opponentSolutions.includes(answer);
  };

const drawAnswerArrow = (answer, playerColor) => {
  // Save the current FEN to restore it later
  const currentFEN = game.fen();

  // Attempt to find and draw the arrow from the current game state first
  let moves = game.moves({ verbose: true });
  let move = moves.find(m => m.san === answer);

  if (move) {
    // Draw arrow using the color from the move found in the current perspective
    drawArrow(move.from, move.to, move.color);
    return; // Exit after drawing, since the move was found
  }

  // If the move wasn't found, adjust the game for the opponent's perspective and try again
  const fenParts = currentFEN.split(' ');
  fenParts[1] = playerColor === 'white' ? 'b' : 'w'; // Flip the turn to check the opponent's perspective
  game.load(fenParts.join(' '));

  // Search moves from the adjusted perspective
  moves = game.moves({ verbose: true });
  move = moves.find(m => m.san === answer);

  if (move) {
    // Draw the arrow using the opponent's perspective if the move is found
    drawArrow(move.from, move.to, move.color);
  } else {
    // Log error if the move isn't found in either perspective
    console.error("Move not found for answer:", answer);
  }

  // Restore the original game state regardless of finding the move or not
  game.load(currentFEN);
};


  if (quizAnswer) {
    const isCorrectAnswer = checkAnswer(quizAnswer, solutions);
    if (isCorrectAnswer) {
      markSolutionAsCorrect(quizAnswer);
      setFeedback(getRandomPositiveFeedback());
      drawAnswerArrow(quizAnswer, playerColor);
    } else {
      setFeedback(getRandomNegativeFeedback());
      flashCanvasRed();
    }
    // Mark this answer as processed
    setLastProcessedAnswer(quizAnswer);
  }
}, [quizAnswer, game, playerColor, solutions, drawArrow, lastProcessedAnswer]);



useEffect(() => {
  if (shouldShowQuiz) {
    setupCanvas();
  }
}, [shouldShowQuiz, chessboardSize]);




    useEffect(() => {
      if (isQuizCompleted && isSuccessful) {
        // After quiz completion, wait 1.5 seconds, then do something (e.g., hide the container)
        const timer = setTimeout(() => {
          setShouldShowQuiz(false); // Hide the entire quiz container
          updateShowQuiz(false);
          // Reset states or prepare for a new quiz round as needed
        }, 2500);

        return () => clearTimeout(timer);
      }
    }, [isQuizCompleted]); // Make sure to include all state setters used in the cleanup or timeout callback in the dependency array if they're used

  useEffect(() => {
    console.log('Updated solutions:', solutions);

    // Check if the quiz is completed
    if (
      solutions.playerSolutions.length > 0 ||
      solutions.opponentSolutions.length > 0
    ) {
      if (
        correctAnswers.length ===
        solutions.playerSolutions.length + solutions.opponentSolutions.length
      ) {
        console.log(
          'quiz completed. correctAnswer.length = ' +
            correctAnswers.length +
            ' Solutions length: ' +
            solutions.playerSolutions.length +
            solutions.opponentSolutions.length
        );
        setIsQuizCompleted(true);
        setIsSuccessful(true);
      }
    }
  }, [correctAnswers, solutions]);



const handleGiveUp = () => {
  setIsQuizCompleted(true);
  setIsSuccessful(false);
  updateShowQuiz(false);
  console.log('In handle give up')

  // Use the correct reference to arrow solutions from state
  arrowSolutions.playerArrows.forEach(solution => {
    drawArrow(solution.from, solution.to, solution.color); // Use `solution`, not `arrowSolutions`
  });

  arrowSolutions.opponentArrows.forEach(solution => {
    drawArrow(solution.from, solution.to, solution.color); // Again, use `solution`
  });
};

return (
  <div>
    <div className='chessboard-and-button-wrapper'>
    <div className="canvas-overlay" ref={overlayRef} style={{ display: 'none' }}></div>
    {shouldShowQuiz && (
      <button
        className={`peek-button ${!isQuizContainerCollapsed ? 'off' : ''}`}
        onClick={toggleQuizContainer}
        style={{ position: 'absolute', zIndex: 5, top: '100%', right: '0%' }}
      >
        {isQuizContainerCollapsed ? 'Show Quiz' : 'Hide Quiz'}
      </button>
    )}
      {shouldShowQuiz && (
        <>
          <div className="quiz-container" style={{ position: 'absolute', display: isQuizContainerCollapsed ? 'none' : 'block' }}>
            {/* Your quiz container content */}
            {!isQuizCompleted ? (
              <>
                <p>Identify all possible checks and captures: (draw arrows or enter as text)</p>
                {feedback && <p>{feedback}</p>}
                <div>
                  <>
                    {solutions.playerSolutions && solutions.playerSolutions.length > 0 && (
                      <>
                        <p>{playerColor.toUpperCase()}'S SOLUTIONS:</p>
                        {solutions.playerSolutions.map((solution, index) => (
                          <div key={index} className={`solution-box ${correctAnswers.includes(solution) ? 'correct-answer' : ''}`}></div>
                        ))}
                      </>
                    )}
                    {solutions.opponentSolutions && solutions.opponentSolutions.length > 0 && (
                      <>
                        <p>{opponentColor.toUpperCase()}'S SOLUTIONS:</p>
                        {solutions.opponentSolutions.map((solution, index) => (
                          <div key={index} className={`solution-box ${correctAnswers.includes(solution) ? 'correct-answer' : ''}`}></div>
                        ))}
                      </>
                    )}
                  </>
                </div>
                <button onClick={handleGiveUp}>Give Up</button>
              </>
            ) : (
              <>
                <p className="quiz-success-message">
                  {isSuccessful ? 'Good job!' : 'Solutions:'}
                </p>
                {!isSuccessful && (
                <>
                  <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <div>
                      <p>{playerColor.toUpperCase()}:</p>
                      {solutions.playerSolutions.map((solution, index) => (
                        <div key={index} className="solution-box answer">{solution}</div>
                      ))}
                    </div>
                    <div>
                      <p>{opponentColor.toUpperCase()}:</p>
                      {solutions.opponentSolutions.map((solution, index) => (
                        <div key={index} className="solution-box answer">{solution}</div>
                      ))}
                    </div>
                  </div>
                  <button onClick={closeQuizMessage} style={{ marginTop: '10px' }}>Close</button>
                </>
                )}
              </>
            )}
          </div>
        <div className={'canvas-blocking'}>
          <canvas ref={canvasRef} style={{ zIndex: 100, position: 'absolute', top: 0, left: 0, pointerEvents: 'auto'}}></canvas>
        </div>
        </>
      )}
    </div>
  </div>
);

};

export default QuizComponent;
