import React, { Component } from "react";
import PropTypes from "prop-types";
import { Chess } from "chess.js";
import GameOverModal from '../Integrations/GameOverModal';
import { openingBook } from '../Integrations/OpeningBook';
import PromotionModal from '../Integrations/PromotionModal';
import { renderMoveHistory } from '../Integrations/MoveHistoryTable';
import ChessboardCustom from '../components/ChessboardCustom';


const STOCKFISH = window.STOCKFISH;
const game = new Chess();

class Journal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialFen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
      fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
      playerColor: 'white',
      displayFen: null,
      isViewingCurrentPosition: true,
      isPromotionOpen: false,
      promotion: 'q',
      promotionDetails: null,
      history: [],
      dropSquareStyle: {},
      squareStyles: {},
      pieceSquare: "",
      legalMoves: [],
      hasResigned: false,
      gameOver: false,
      gameOverOpen: false,
      gameResultMessage: '',
      gameResult: '',
      showKeyboardButtons: false,
      showShareOptions: false,
      datePlayed: null,
      capturedPieces: {
            white: [],
            black: []
          }
    };
    this.moveHistoryRef = React.createRef();
    this.shareButtonRef = React.createRef();
    this.dropdownRef = React.createRef();
    this.handlePromotion = this.handlePromotion.bind(this);
    this.engineGameInstance = this.engineGame();
  }

async componentDidMount() {
  game.reset()
  this.setDatePlayed();
  document.addEventListener('mousedown', this.handleClickOutside);
  this.engineGameInstance.start();
  if (this.state.fen && this.state.fen !== 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1') {
        // Load the game from an initial FEN if no history is present
        game.load(this.state.fen);
        this.setState({ initialFen: this.state.fen }); // Set initialFen to this FEN to ensure it doesn't change unless needed
//        console.log('Game loaded from FEN:', this.state.fen);
    }

  setTimeout(() => {
    if (this.engineGame) {
      this.engineGameInstance.evaluatePosition(this.state.fen);
    } else {
      console.error("Engine game instance or evaluatePosition function is not correctly defined.");
    }
  }, 1000);  // Adjust the timeout duration as needed  console.log('Component mounted, engine game instance started');
}


loadGameFromHistory = (history) => {
  game.reset(); // Reset the game to its initial state

  // Apply each move in the history to the game
  history.forEach(moveSAN => {
    const move = game.move(moveSAN, { sloppy: true });
    if (!move) {
      console.error("Invalid or illegal move in history:", moveSAN);
      return;
    }
  this.setState({
      fen: game.fen(),
      history: game.history({ verbose: true }),
      pieceSquare: "",
      selectedMoveIndex: game.history().length - 1,
  });
  });

  this.setState({
    fen: game.fen(),
    history: game.history({ verbose: true }) // or just history if you store moves as SAN strings
  }, () => {
    console.log("Game loaded from history:", game.fen());
  });
};

componentDidUpdate(prevProps, prevState) {
  const moveHistoryElement = this.moveHistoryRef.current;
  if (moveHistoryElement) {
    moveHistoryElement.scrollTop = moveHistoryElement.scrollHeight;
  }
  if (prevState.selectedMoveIndex !== this.state.selectedMoveIndex) {
    this.scrollToSelectedMove();
  }
    if (prevState.fen !== this.state.fen) {
        console.log('Fen updated');
        this.engineGameInstance.evaluatePosition(this.state.fen);
    }
    // Otherwise, check if 'displayFen' has changed
    else if (prevState.displayFen !== this.state.displayFen) {
        console.log('Display fen updated');
        this.engineGameInstance.evaluatePosition(this.state.displayFen);
    }
}

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
//    this.engineGameInstance.terminate();
//    console.log('component did unmount');
  }

handleClickOutside = (event) => {
  if (
    this.shareButtonRef.current &&
    !this.shareButtonRef.current.contains(event.target) &&
    this.dropdownRef.current &&
    !this.dropdownRef.current.contains(event.target)
  ) {
    this.setState({ showShareOptions: false });
  }
};


scrollToSelectedMove = () => {
  const { selectedMoveIndex } = this.state;
  const moveHistoryElement = this.moveHistoryRef.current;
  if (moveHistoryElement) {
    const rowHeight = 17;
    const scrollTop = selectedMoveIndex * rowHeight;
    moveHistoryElement.scrollTop = scrollTop - (moveHistoryElement.offsetHeight / 2) + (rowHeight / 2); // Center the selected move in the scroll view
  }
}

//copyFenToClipboard = () => {
//  const fen = this.state.fen; // Assuming 'fen' is stored in your component's state
//  if (!navigator.clipboard) {
//    // Clipboard API not available
//    console.error('Clipboard API not available.');
//    return;
//  }
//  navigator.clipboard.writeText(fen).then(() => {
////    console.log('FEN copied to clipboard:', fen);
//    // Optionally, notify the user that the FEN was successfully copied
//  }).catch(err => {
//    console.error('Failed to copy FEN to clipboard:', err);
//    // Optionally, notify the user that there was an error copying the FEN
//  });
//};

  setDatePlayed = () => {
    const today = new Date();
    const datePlayed = `${today.getFullYear()}.${today.getMonth() + 1}.${today.getDate()}`;
    this.setState({ datePlayed });
  };

generatePgnTags = () => {
  const { eventName, datePlayed } = this.state; // Assuming these exist in your state
  const whiteName = this.state.playerColor === "white" ? "You" : "Stockfish";
  const blackName = this.state.playerColor === "black" ? "You" : "Stockfish";
  const result = this.state.gameOver ? this.state.gameResult : "*"; // Use your game's state to determine result

  const tags = [
    `[Event "${eventName || 'Casual Game'}"]`,
    `[Site "Train Chess"]`,
    `[Date "${datePlayed || '????.??.??'}"]`,
    `[Round "?"]`,
    `[White "${whiteName}"]`,
    `[Black "${blackName}"]`,
    `[Result "${result}"]`,
  ];

  return tags.join("\n") + "\n\n";
};


generatePgn = () => {
  const tags = this.generatePgnTags();
  const movesPgn = game.pgn({
    max_width: 5,
    newline_char: "\n",
  });

  return tags + movesPgn;
};

copyPgnToClipboard = () => {
  const pgn = this.generatePgn(); // Assuming you have a method that generates the PGN string of the game
  if (!navigator.clipboard) {
    // Clipboard API not available
    console.error('Clipboard API not available.');
    return;
  }
  navigator.clipboard.writeText(pgn).then(() => {
//    console.log('PGN copied to clipboard:', pgn);
    // Optionally, notify the user that the PGN was successfully copied
  }).catch(err => {
    console.error('Failed to copy PGN to clipboard:', err);
    // Optionally, notify the user that there was an error copying the PGN
  });
};


openInLichess = () => {
  const fen = this.state.fen; // Assuming 'fen' holds the current game position
  const url = `https://lichess.org/analysis/standard/${encodeURIComponent(fen)}?openBrowser=true`;
  window.open(url, "_blank"); // Open the URL in a new tab
};

  handleMove = (from, to, promotion) => {
    if (this.state.gameOver) {
     return;
    }
    if (!this.state.isViewingCurrentPosition) {
    // Prevent making moves if not viewing the current position
    return;
  }
    game.move({ from, to, promotion });

    const newSquareStyles = squareStyling({ pieceSquare: "", history: game.history({ verbose: true }) });
    const checkHighlightStyles = this.updateCheckHighlight(); // Get styles for highlighting check
    const combinedSquareStyles = { ...newSquareStyles, ...checkHighlightStyles }; // Combine styles

    this.setState({
      fen: game.fen(),
      history: game.history({ verbose: true }),
      pieceSquare: "",
      squareStyles: combinedSquareStyles,
      selectedMoveIndex: game.history().length - 1,
    }, () => {
      this.announceResult();
    });
//    console.log("History: " + this.state.history);
  }

calculateMaterialAdvantage(fen) {
  const pieceValues = { 'p': 1, 'n': 3, 'b': 3, 'r': 5, 'q': 9 };
  let whiteMaterial = 0;
  let blackMaterial = 0;

  // Extract the piece positions from the FEN string
  const pieces = fen.split(' ')[0];

  // Iterate through each character in the FEN piece placement
  for (const char of pieces) {
    if (char in pieceValues) {
      // Add value if the piece is black (lowercase in FEN denotes black pieces)
      blackMaterial += pieceValues[char];
    } else if (char.toLowerCase() in pieceValues) {
      // Add value if the piece is white (uppercase in FEN denotes white pieces)
      whiteMaterial += pieceValues[char.toLowerCase()];
    }
    // Ignore slashes and numbers (which denote empty squares) in FEN
  }

  // Material advantage: positive if white has more material, negative if black has more
  return whiteMaterial - blackMaterial;
}

calculateDifferentialCaptures(fen) {
  // Starting counts for a full chess set
  const startingCounts = { P: 8, R: 2, N: 2, B: 2, Q: 1, K: 1 };
  // Current counts initialized to 0
  const currentCounts = { w: { P: 0, R: 0, N: 0, B: 0, Q: 0, K: 0 }, b: { P: 0, R: 0, N: 0, B: 0, Q: 0, K: 0 } };

  // Parse the piece placement part of the FEN
  const piecesPlacement = fen.split(' ')[0];
  piecesPlacement.replace(/\d/g, '').split('').forEach(piece => {
    // Increment count for white and black pieces
    if (currentCounts.w[piece.toUpperCase()] !== undefined) {
      piece === piece.toUpperCase() ? currentCounts.w[piece]++ : currentCounts.b[piece.toUpperCase()]++;
    }
  });

  // Calculate differential captures, ensuring we don't show duplicates
  const differentialCaptures = { white: {}, black: {} };
  Object.keys(startingCounts).forEach(piece => {
    const whiteCaptures = startingCounts[piece] - currentCounts.w[piece];
    const blackCaptures = startingCounts[piece] - currentCounts.b[piece];

    // Calculate the differential for each piece type
    const differential = whiteCaptures - blackCaptures;

    if (differential > 0) {
      // If positive, white has captured more of this piece
      differentialCaptures.white[piece] = differential;
    } else if (differential < 0) {
      // If negative, black has captured more of this piece
      differentialCaptures.black[piece] = -differential; // Use the positive value of the differential
    }
    // If differential is 0, it means an equal number of pieces were captured, so we don't show these pieces
  });

  return differentialCaptures;
}



renderCapturedPieces() {
  const materialAdvantage = this.calculateMaterialAdvantage(this.state.fen);
  const differentialCaptures = this.calculateDifferentialCaptures(this.state.fen);

  const normalPieceImages = {
  wP: process.env.PUBLIC_URL + "pieces/normal/wP.png",
  wR: process.env.PUBLIC_URL + "pieces/normal/wR.png",
  wN: process.env.PUBLIC_URL + "pieces/normal/wN.png",
  wB: process.env.PUBLIC_URL + "pieces/normal/wB.png",
  wQ: process.env.PUBLIC_URL + "pieces/normal/wQ.png",
  wK: process.env.PUBLIC_URL + "pieces/normal/wK.png",
  bP: process.env.PUBLIC_URL + "pieces/normal/bP.png",
  bR: process.env.PUBLIC_URL + "pieces/normal/bR.png",
  bN: process.env.PUBLIC_URL + "pieces/normal/bN.png",
  bB: process.env.PUBLIC_URL + "pieces/normal/bB.png",
  bQ: process.env.PUBLIC_URL + "pieces/normal/bQ.png",
  bK: process.env.PUBLIC_URL + "pieces/normal/bK.png",
};

const renderPieceImages = (color) => {
  // Convert 'w' or 'b' to uppercase for image retrieval
  const colorPrefix = color === 'white' ? 'w' : 'b';

  return Object.entries(differentialCaptures[color]).flatMap(([pieceType, count]) =>
    Array.from({ length: count }, (_, i) => {
      const imageKey = `${colorPrefix}${pieceType.toUpperCase()}`;
      return (
        <img
          key={`${color}-${pieceType}-${i}`}
          src={normalPieceImages[imageKey]}
          alt={`${colorPrefix}${pieceType.toUpperCase()}`}
          style={{ width: '20px', margin: '2px' }}
        />
      );
    })
  );
};

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {renderPieceImages('white')}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {renderPieceImages('black')}
      </div>

      {/* Material advantage display */}
        <div>
          {materialAdvantage > 0 ? (
            <span style={{ color: 'gray', fontWeight: 'bold' }}>+{materialAdvantage}</span>
          ) : materialAdvantage < 0 ? (
            <span style={{ color: 'gray', fontWeight: 'bold' }}>{materialAdvantage}</span>
          ) : ''}
        </div>
    </div>
  );
}

  handlePromotion(piece) {
    // Set the state with the selected promotion piece
    const { sourceSquare, targetSquare } = this.state.promotionDetails;
    this.setState({ promotion: piece }, () => {
      // Close the promotion modal and continue with the promotion move
//      console.log(`Selected promotion piece: ${this.state.promotion}`);
      // You might want to continue with making the actual promotion move here
    this.handleMove(sourceSquare, targetSquare, this.state.promotion)
    this.setState({promotion: 'q', promotionDetails: null, isPromotionOpen: false})
    });
  }

isPromotionMove = (sourceSquare, targetSquare) => {
  // Determine the rank (row) of the target square
  const targetRank = targetSquare[1]; // Get the rank part of the square

  // Determine if the source square contains a pawn
  const pieceOnSource = game.get(sourceSquare);
  if (!pieceOnSource || pieceOnSource.type !== 'p') {
    return false; // Not a pawn, so it can't be a promotion move
  }

  const isPawn = pieceOnSource.type === 'p';
  const color = pieceOnSource.color;

  // Check for white pawn promotion to rank 8 or black pawn to rank 1
  if (isPawn && ((color === 'w' && targetRank === '8') || (color === 'b' && targetRank === '1'))) {
    return true;
  } else {
    // If neither condition is met, it's not a promotion move
    return false;
  }
};


onDrop = ({ sourceSquare, targetSquare }) => {

  this.setState({promotion: 'q', promotionDetails: null, isPromotionOpen: false})
  // Fetch all legal moves for the current game state in a verbose format
  const legalMoves = game.moves({ verbose: true });

  // Check if the attempted move is legal
  const moveIsLegal = legalMoves.some(move => move.from === sourceSquare && move.to === targetSquare);

  if (!moveIsLegal) {
    // If the move isn't legal, return 'snapback' to revert the piece movement
    return 'snapback';
  }

  // Determine if the move is a promotion and handle accordingly
  const isPromotion = this.isPromotionMove(sourceSquare, targetSquare, game.get(sourceSquare));
//  let promotion = 'q'; // Default promotion to queen for simplicity
  if (isPromotion) {
    this.setState({isPromotionOpen: true, promotionDetails: {sourceSquare, targetSquare} });
  }
  else {
    this.handleMove(sourceSquare, targetSquare, this.state.promotion)
  }
};


onSquareClick = square => {

    this.setState({promotion: 'q', promotionDetails: null, isPromotionOpen: false})
    if (this.state.gameOver) {
     return;
    }
  const piece = game.get(square); // Retrieve the piece at the clicked square

  // If the clicked square has a piece and it's the player's color, highlight possible moves
  if (piece && piece.color === this.state.playerColor[0]) {
    // Calculate possible moves for the selected piece
    const moves = game.moves({ square: square, verbose: true });
    const squaresToHighlight = moves.map(move => move.to); // Get squares to highlight based on possible moves

// Function to determine if a square contains a piece
const hasPiece = (square) => {
  const piece = game.get(square);
  return piece;
};

const darkSquare = 'var(--dark-square-color)'; // Example dark square color
const lightSquare = 'var(--light-square-color)'; // Example light square color

// Calculate highlight styles for squares
const highlightStyles = squaresToHighlight.reduce((acc, curr) => {
  const squareColor = (curr.charCodeAt(0) - 'a'.charCodeAt(0) + parseInt(curr[1], 10)) % 2 === 0 ? lightSquare : darkSquare;
  const highlightColor = squareColor === lightSquare ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)'; // Use semi-transparent white and black

  if (hasPiece(curr)) {
    // Highlight corners for squares with pieces
    return {
      ...acc,
      [curr]: {
        background: `
          linear-gradient(to bottom right, ${highlightColor} 30%, transparent 30%) 0 0,
          linear-gradient(to bottom left, ${highlightColor} 30%, transparent 30%) 100% 0,
          linear-gradient(to top left, ${highlightColor} 30%, transparent 30%) 100% 100%,
          linear-gradient(to top right, ${highlightColor} 30%, transparent 30%) 0 100%
        `,
        backgroundSize: "40% 40%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "0 0, 100% 0, 100% 100%, 0 100%",
      },
    };
  } else {
    // Highlight with a circle for empty squares
    return {
      ...acc,
      [curr]: {
        background: `radial-gradient(circle, ${highlightColor} 25%, transparent 27%)`,
        borderRadius: "50%",
      },
    };
  }
}, {});

    // Update state to select the piece and highlight possible moves
    this.setState({
      pieceSquare: square,
      squareStyles: { ...squareStyling({ pieceSquare: square, history: game.history({ verbose: true }) }), ...highlightStyles },
      highlightedSquares: [] // Clear any right-click highlights
    });
  } else {
    // Deselect the piece if another square is clicked or if it's an illegal move
    this.setState({
      pieceSquare: "",
      squareStyles: { ...squareStyling({ history: game.history({ verbose: true }) }) },
      highlightedSquares: [] // Clear any right-click highlights
    });
  }

      // Deselect the piece if the same square is clicked again
    if (square === this.state.pieceSquare) {
        this.setState({
            pieceSquare: "",
            squareStyles: { ...squareStyling({ pieceSquare: square, history: game.history({ verbose: true }) }) },
            highlightedSquares: [] // Clear any right-click highlights
        });
        return; // Exit the function to prevent further processing
    }

  // If there's a piece selected and it's a different square, attempt the move
  if (this.state.pieceSquare && this.state.pieceSquare !== square) {
    const isLegalMove = game.moves({ verbose: true }).some(move => move.from === this.state.pieceSquare && move.to === square);

    if (isLegalMove) {

          // Determine if the move is a promotion and handle accordingly
      const isPromotion = this.isPromotionMove(this.state.pieceSquare, square, piece);
    //  let promotion = 'q'; // Default promotion to queen for simplicity
      if (isPromotion) {
        this.setState({isPromotionOpen: true, promotionDetails: { sourceSquare: this.state.pieceSquare, targetSquare: square } });
      }
      else {
        this.handleMove(this.state.pieceSquare, square, this.state.promotion)
      }
    }
  }
};

//onSquareRightClick = square => {
//  let { highlightedSquares, squareStyles } = this.state;
//
//  // Ensure highlightedSquares is an array
//  if (!Array.isArray(highlightedSquares)) {
//    highlightedSquares = [];
//  }
//
//  const squareIndex = highlightedSquares.indexOf(square);
//  let newHighlightedSquares;
//  let newSquareStyles = { ...squareStyles };
//
//  if (squareIndex === -1) {
//    // If the square isn't already highlighted, add it
//    newHighlightedSquares = [...highlightedSquares, square];
//    newSquareStyles[square] = { backgroundColor: "rgba(144, 238, 144, 0.5)" }; // Highlight the square
//  } else {
//    // If the square is already highlighted, remove the highlight
//    newHighlightedSquares = highlightedSquares.filter(s => s !== square);
//    delete newSquareStyles[square]; // Remove specific square's style
//  }
//
//  this.setState({
//    highlightedSquares: newHighlightedSquares,
//    squareStyles: newSquareStyles
//  });
//};




updateCheckHighlight = () => {
  let squareStyles = {};

  if (game.inCheck()) {
    // The king is in check, find the king's position
    const board = game.board();
    let kingPosition = null;
//    console.log('CHECK!')

    for (let i = 0; i < board.length; i++) {
      for (let j = 0; j < board[i].length; j++) {
        const piece = board[i][j];
        if (piece && piece.type === 'k' && piece.color === game.turn()) {
          // Chess.js uses 0-indexed arrays, so add 1 to match algebraic notation
          const row = '87654321'[i];
          const col = 'abcdefgh'[j];
          kingPosition = col + row;
          break;
        }
      }
      if (kingPosition) break;
    }

    if (kingPosition) {
      // Apply a red circle highlight style to the king's position
      squareStyles[kingPosition] = {
        background: 'radial-gradient(circle at center, rgba(255, 0, 0, 1) 0%, rgba(255, 0, 0, 0) 80%)'
      };
    }
  }

  return squareStyles
};

handleUserMove = (e) => {
  e.preventDefault(); // Prevent form submission from reloading the page

   if (this.state.gameOver) {
     return;
  }

  if (!this.state.isViewingCurrentPosition) {
    console.log('Not current position')
    // Prevent making moves if not viewing the current position
    return;
  }

  const userInputMove = this.state.userInputMove.trim();
  const legalMoves = game.moves({ verbose: true }); // Generate all legal moves in verbose mode to access SAN notation
  const isLegalMove = legalMoves.some(move => move.san === userInputMove); // Check if the user's move is legal

  if (isLegalMove) {
    const result = game.move(userInputMove, { sloppy: true });
    if (result) {

      // Logic for updating state after a successful move
      const newSquareStyles = squareStyling({ pieceSquare: "", history: game.history({ verbose: true }) });
      const checkHighlightStyles = this.updateCheckHighlight(); // Get styles for highlighting check
      const combinedSquareStyles = { ...newSquareStyles, ...checkHighlightStyles }; // Combine styles

      this.setState({
        fen: game.fen(),
        history: game.history({ verbose: true }),
        userInputMove: "", // Clear the input field
        selectedMoveIndex: game.history().length - 1,
        squareStyles: combinedSquareStyles
      }, () => {
        this.announceResult();
      });
    } else {
      // Handle unexpected error if a legal move could not be executed
      this.flashIllegalMove();
    }
  } else {
    // Handle case where the move is not legal
    this.flashIllegalMove();
    this.setState({ userInputMove: "" }); // Optionally clear the input field if the move is illegal
  }
};


handleBackspace = () => {
  this.setState(prevState => ({
    userInputMove: prevState.userInputMove.slice(0, -1) // Removes the last character
  }));
};

handleInputChange = (event) => {
  const allowedChars = "RNBQK+=xabcdefgh12345678#O-"; // Include both uppercase and lowercase for simplicity
  const value = event.target.value;
  let formattedInput = "";

  // Iterate through each character in the input
  for (let char of value) {
    // Capitalize lowercase r, n, b, q, k
    if ("rnqko".includes(char)) {
      formattedInput += char.toUpperCase();
    }
    // Lowercase uppercase A, B, C, D, E, F, G, H
    else if ("ACDEFGH".includes(char)) {
      formattedInput += char.toLowerCase();
    }
    // Keep other allowed characters as they are
    else if (allowedChars.includes(char)) {
      formattedInput += char;
    }
    // Ignore characters not in the allowed list
  }

  // Update the state with the formatted input
  this.setState({ userInputMove: formattedInput });
};


  flashIllegalMove = () => {
  // Define a style that applies a red tint to all squares
  const illegalMoveStyles = Object.fromEntries(
    Array.from({ length: 64 }).map((_, index) => {
      const file = 'abcdefgh'[index % 8];
      const rank = 8 - Math.floor(index / 8);
      const square = `${file}${rank}`;
      return [square, { backgroundColor: 'rgba(255, 0, 0, 0.3)' }];
    })
  );

  // Temporarily set all squares to indicate an illegal move
  this.setState({
    squareStyles: illegalMoveStyles
  });

  // Remove the flash after a short delay
  setTimeout(() => {
    this.setState({
      squareStyles: squareStyling({ pieceSquare: "", history: this.state.history }), // Reset to the original square styles based on the current game state
    });
  }, 500); // Adjust timing as needed
};


highlightBestMove(from, to) {
    console.log(`Hint: best move from ${from} to ${to}`);

    // Generate base styles from game history
    const baseStyles = squareStyling({ pieceSquare: "", history: game.history({ verbose: true }) });

    // Get additional styles from check conditions or other game-specific highlights
    const checkHighlights = this.updateCheckHighlight();

    // Prepare new square styles combining base styles, check highlights, and move highlights
    const newSquareStyles = {
        ...baseStyles,
        ...checkHighlights,
        [from]: { ...baseStyles[from], backgroundColor: 'rgba(255, 215, 0, 0.7)' }, // Merge existing styles with new highlight color
        [to]: { ...baseStyles[to], backgroundColor: 'rgba(255, 215, 0, 0.7)' }       // Merge existing styles with new highlight color
    };

    // Update the state with new styles
    this.setState({
        squareStyles: newSquareStyles
    });
}

updateGameStateAfterMove(from, to) {
//    console.log(`Move made by engine: ${from}-${to}`);
    this.setState({
        fen: game.fen(),
        history: game.history({ verbose: true }),
        pieceSquare: "",
        selectedMoveIndex: game.history().length - 1,
    }, () => {
//        console.log('History from engine: ' + this.state.history.map(move => move.san).join(', '));
        this.announceResult();
        setTimeout(() => {
            const newSquareStyles = { ...squareStyling({ pieceSquare: "", history: game.history({ verbose: true }) }), ...this.updateCheckHighlight() };
            this.setState({ squareStyles: newSquareStyles });
        }, 500);
    });
}

engineGame = options => {
    options = options || {};


if (typeof SharedArrayBuffer !== 'undefined') {
    console.log("SharedArrayBuffer is supported!");
    const arrayBuffer = true
} else {
    console.log("SharedArrayBuffer is not supported. Check your server headers!");
    const arrayBuffer = false
}

let engineScript; // Declare the variable outside the if/else scope

// Checking if SharedArrayBuffer is supported
if (true) {
    engineScript = typeof SharedArrayBuffer !== 'undefined' ? 'stockfish-nnue-16.wasm' : 'stockfish-nnue-16-single.js';
    console.log('Loading Stockfish 16');
} else {
    engineScript = 'stockfish.js';
    console.log('Loading Stockfish 8');
}
// You can now use `engineScript` here and it will have the expected value

// Creating the engine worker with the selected script
const engine = typeof STOCKFISH === "function" ? STOCKFISH() : new Worker(options.stockfishjs || engineScript);

    let engineStatus = {
        engineReady: false,
        search: null,
        isHintMode: false // Add a flag to track hint mode
    };

    let playerColor = this.state.playerColor;

    // Helper function to send commands to the engine
    function uciCmd(cmd) {
        engine.postMessage(cmd);
    }

    // Function to get all moves from game history
    function get_moves() {
        let moves = "";
        let history = game.history({ verbose: true });
        for (let i = 0; i < history.length; ++i) {
            let move = history[i];
            moves += ` ${move.from}${move.to}${move.promotion || ''}`;
        }
        return moves;
    }

// Initialize the Stockfish engine and set up listeners
engine.onmessage = event => {
    const line = event.data;
//    console.log('Engine:', line);

    if (line === "uciok") {
        console.log("Engine loaded.");
        uciCmd('ucinewgame');
        uciCmd('isready');
    } else if (line === "readyok") {
        console.log("Engine ready.");
        // Set engine to highest skill immediately after it is ready
        setEngineOptions();
    } else if (line.startsWith('bestmove')) {
        handleBestMove(line);
    } else if (line.startsWith('info depth')) {
        updateEvaluationFromEngineOutput(line);
    }
};

// Configure the engine for maximum performance and no hints
function setEngineOptions() {
    uciCmd('setoption name Skill Level value 20');
    uciCmd('setoption name Move Overhead value 30');
    uciCmd('setoption name Threads value 4'); // Use 4 threads for calculation
    uciCmd('setoption name Hash value 1024'); // Set hash size to 1024 MB
}

// Function to handle best move output from the engine
function handleBestMove(line) {
    const move = line.split(' ')[1];
    console.log('Best move recommended by engine:', move);
    // Additional logic can be implemented here to display the move on the UI
}

let bestEvaluation = {
    depth: 0,
    score: null,
    move: null
};

function updateEvaluationFromEngineOutput(output) {
    const depthMatch = output.match(/info depth (\d+)/);
    const scoreMatch = output.match(/score cp (\-?\d+)/);
    const pvMatch = output.match(/pv ([a-h1-8]+ [a-h1-8]+)/); // We'll need to adjust this if you want the full line of moves

    if (depthMatch && pvMatch) {
        const depth = parseInt(depthMatch[1], 10);
        const score = scoreMatch ? parseInt(scoreMatch[1], 10) : null;
        const move = pvMatch ? pvMatch[1].split(' ')[0] : null; // Gets only the first move

        // Check if this is the deepest analysis so far
        if (depth > bestEvaluation.depth) {
            bestEvaluation = { depth, score, move };
            console.log(`Updated best evaluation: Depth ${depth}, Score ${score}, Move ${move}`);
        }
    }
}

// Function to prepare the engine to suggest the best move
// Function to prepare the engine to suggest the best move using a given FEN
const evaluatePosition = (fen) => {
    // First, stop any ongoing analysis to ensure a clean start
    uciCmd('stop');

    // Delay the new position command to ensure 'stop' command has been processed
    setTimeout(() => {
        // Set the position using the provided FEN
        uciCmd("position fen " + fen);

        // Start analysis with the new position
        // Here you can specify depth, time, or other engine-specific parameters
        uciCmd('go depth 20'); // Example: specify depth if needed
    }, 100); // Adjust this timeout based on the responsiveness of your Stockfish setup
};


// Initialize the engine
function start() {
    uciCmd("uci");
    console.log('Starting engine...');
}

// Terminate the engine when it's no longer needed
const terminate = () => {
    engine.terminate();
    console.log('Engine terminated');
};

return { start, evaluatePosition, terminate };

};

announceResult = () => {
  let message = "Game is ongoing.";
  let gameOver = false;
  let result = '';

  if (this.state.hasResigned) {
    const winner = this.state.playerColor === "white" ? "Black" : "White";
    message = `${winner} wins by resignation!`;
    result = this.state.playerColor === "white" ? "0-1" : "1-0";
    gameOver = true;
  } else if (game.isCheckmate()) {
    // Checkmate
    const winner = game.turn() === "b" ? "White" : "Black";
    message = `Checkmate. ${winner} wins!`;
    result = game.turn() === "b" ? "0-1" : "1-0";
    gameOver = true;
  } else if (game.isStalemate()) {
    // Draw or Stalemate
    message = "Game is a draw (stalemate).";
    result = "1/2-1/2";
    gameOver = true;
  } else if (game.isThreefoldRepetition()) {
    // Threefold repetition
    message = "Game is a draw (threefold repetition).";
    result = "1/2-1/2";
    gameOver = true;
  } else if (game.isInsufficientMaterial()) {
    // Insufficient material
    message = "Game is a draw (insufficient material).";
    result = "1/2-1/2";
    gameOver = true;
  } else if (game.inCheck()) {
    // Check
    const inCheck = game.turn() === "b" ? "Black" : "White";
    message = `${inCheck} is in check.`;
    // No game over, so we don't change the result or gameOver flag
  }

  // Update the state to reflect game outcome
  if (gameOver) {
    this.setState({
      gameOver: true,
      gameOverOpen: true,
      gameResultMessage: message,
      gameResult: result,
    });
    this.engineGameInstance.terminate();
  }
};

handleTakeBackMove = () => {
  // Check if it's currently the player's turn; if not, do not proceed
  if (game.turn() !== this.state.playerColor[0]) {
//    console.log("It's not your turn.");
    return; // Exit if it's not the player's turn
  }

  // Ensure there are at least two plies (a full move) in the history to take back
  if (this.state.history.length < 2) {
//    console.log("Not enough moves to take back.");
    return; // Exit if there aren't enough moves
  }

  // Remove the last two moves from the history to ensure it's the player's turn again
  const updatedHistory = this.state.history.slice(0, -2);
  const startFen = this.state.initialFen || game.defaultFen(); // Use a default FEN if fen is not provided
  game.load(startFen); // Load the starting FEN
  updatedHistory.forEach(move => {
    game.move(move.san);
  });

  // Update the state with the new game state
  this.setState({
    fen: game.fen(), // Update the FEN to the new position
    history: updatedHistory, // Update the history to reflect the taken back moves
    squareStyles: squareStyling({ pieceSquare: "", history: updatedHistory }),
    isViewingCurrentPosition: true, // Allow the player to make a move in the new position
    selectedMoveIndex: updatedHistory.length - 1, // Update the selected move index
    gameOver: false,
  }, () => {
    // Optional: any additional actions to be performed after updating the state
//    console.log("Move taken back. New position is ready for a move.");
  });
};

handleResigns = () => {
  const isConfirmed = window.confirm("Are you sure you want to resign?");
  if (isConfirmed) {
    // Determine the result based on player color
    const result = this.state.playerColor === "white" ? "0-1" : "1-0";
    // Set state to indicate the player's resignation, update the game result, and then check the game result
    this.setState({ hasResigned: true, gameOver: true, gameOverOpen: true, gameResult: result }, this.announceResult);
  }
};

  toggleKeyboardButtons = () => {
    this.setState(prevState => ({
      showKeyboardButtons: !prevState.showKeyboardButtons,
    }));
  };

    // Function to handle button click
  handleButtonClick = (char) => {
    this.setState(prevState => {
      let newInputMove = prevState.userInputMove + char;
      newInputMove = newInputMove.replace(/^undefined/, '');
      return { userInputMove: newInputMove };
    });
  };

render() {
  const { squareStyles } = this.state;

  // Adjust the buttons for each character, including "#" in the first row
  const buttonsRow1 = ['R', 'N', 'B', 'Q', 'K', 'x', 'O', '-'];
  const buttonsRow2 = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
  const buttonsRow3 = ['1', '2', '3', '4', '5', '6', '7', '8'];

return (
<div className="game-layout">
  <div className="captures-container">
   {this.renderCapturedPieces()}
  </div>
    <div className="chessboard-container">
      <ChessboardCustom
        position={this.state.displayFen || this.state.fen} // Set the FEN string from your state
        onDrop={this.onDrop} // Define the method to handle piece drops
        orientation={this.state.playerColor} // Set the orientation based on player color
        squareStyles={squareStyles} // Custom styles for squares
        onSquareClick={this.onSquareClick} // Define method to handle square clicks
      />
    </div>
      {/* Your chessboard and other UI elements */}
      {this.state.isPromotionOpen && (
        <div className='promotion-modal-container'>
        <PromotionModal
          onPromote={(piece) => this.handlePromotion(piece)}
          pieces={['q', 'r', 'n', 'b']}
          color = {this.state.playerColor === 'white' ? 'w' : 'b'}
        />
        </div>
      )}
      <div className="move-history-container">
        {renderMoveHistory(this, this.state.history, squareStyling)}
      </div>
      <div className="move-input-container">
        <form onSubmit={this.handleUserMove} className="input-form">
          <input
            type="text"
            value={this.state.userInputMove}
            onChange={this.handleInputChange}
            placeholder="Enter move in algebraic notation (e.g., Nf3)"
            className="move-input"
          />
          {this.state.showKeyboardButtons && (
            <>
              {[buttonsRow1, buttonsRow2, buttonsRow3].map((row, rowIndex) => (
                <div key={rowIndex} className="button-row">
                  {row.map(char => (
                    <button key={char} type="button" onClick={() => this.handleButtonClick(char)} className="keyboard-button">
                      {char}
                    </button>
                  ))}
                </div>
              ))}
              <div className="action-buttons">
                <button type="button" onClick={() => this.handleButtonClick('+')} className="hash-button">+</button>
                <button type="button" onClick={() => this.handleButtonClick('=')} className="hash-button">=</button>
                <button type="button" onClick={() => this.handleButtonClick('#')} className="hash-button">#</button>
                <button type="button" onClick={this.handleBackspace} className="backspace-button">Backspace</button>
                <button type="submit" className="submit-move-button">Submit Move</button>
              </div>
            </>
          )}
        </form>
        <button onClick={this.toggleKeyboardButtons} className={`keyboard-button2 ${!this.state.showKeyboardButtons ? 'off' : ''}`}>
          {this.state.showKeyboardButtons ? 'Hide' : 'Show'} Keyboard Buttons
        </button>
      </div>
      <GameOverModal
        isOpen={this.state.gameOverOpen}
        winner={this.state.gameResultMessage}
        onClose={() => this.setState({ gameOverOpen: false })}
      />
  </div>
);

}


}

export default Journal;

const squareStyling = ({ pieceSquare, history }) => {
  const sourceSquare = history.length && history[history.length - 1].from;
  const targetSquare = history.length && history[history.length - 1].to;

  return {
    [pieceSquare]: { backgroundColor: "rgba(255, 255, 0, 0.4)" }, // color when clicked
    ...(history.length && {
      [sourceSquare]: {
        backgroundColor: "rgba(255, 255, 0, 0.3)" // moved from square
      }
    }),
    ...(history.length && {
      [targetSquare]: {
        backgroundColor: "rgba(255, 255, 0, 0.5)" // moved to square
      }
    })
  };
};
