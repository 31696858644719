import React, { useState, useEffect } from 'react';
import ChessboardCustom from '../components/ChessboardCustom';
import { Chess } from 'chess.js';
import { renderMoveHistory } from '../Integrations/MoveHistoryTable';

const DEFAULT_START_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';

const BoardDisplay = ({ boardUrl, onClose, userTeamPlayer }) => {
  const [chess] = useState(new Chess(DEFAULT_START_FEN));
  const [games, setGames] = useState([]); // Store games from the API
  const [currentGameIndex, setCurrentGameIndex] = useState(0); // Track the current game
  const [selectedMoveIndex, setSelectedMoveIndex] = useState(0); // Track the current move
  const [fen, setFen] = useState(DEFAULT_START_FEN); // Initial FEN
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [squareStyles, setSquareStyles] = useState({}); // Square styling for highlights

  // Helper function to extract username from URL
  const extractUsernameFromUrl = (url) => {
    console.log('url: ' + url);
    return url ? url.split('/').pop() : 'Unknown';
  };

  // Fetch board data from the API
  useEffect(() => {
    const fetchBoardData = async () => {
      try {
        const response = await fetch(boardUrl);
        const data = await response.json();
        console.log('Fetched board data:', data); // Log the full data response

        if (data.games && data.games.length > 0) {
          console.log('Games found:', data.games); // Log the games array

          // Log each game's white and black player usernames or extract them from URL
          data.games.forEach((game, index) => {
            const whiteUsername = game.white?.username || extractUsernameFromUrl(game.white);
            const blackUsername = game.black?.username || extractUsernameFromUrl(game.black);
            console.log(`Game ${index + 1}: White: ${whiteUsername}, Black: ${blackUsername}`);
          });

          setGames(data.games); // Store games
          loadGame(data.games[0]); // Load the first game by default
        } else {
          console.warn('No games found in the response.');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching board data:', error);
        setError('Failed to fetch board data');
        setLoading(false);
      }
    };

    if (boardUrl) {
      fetchBoardData();
    }
  }, [boardUrl, chess]);

// Load a game and highlight a specific move
const loadGame = (game) => {
  chess.reset(); // Reset chessboard
  chess.loadPgn(game.pgn); // Load PGN
  const historyMoves = chess.history({ verbose: true }); // Get moves
  setSelectedMoveIndex(historyMoves.length - 1); // Set current move index to last move
  setFen(chess.fen()); // Set FEN for the chessboard
  highlightMoveAtIndex(historyMoves, historyMoves.length - 1); // Highlight the last move
};

// Highlight the move at the given index
const highlightMoveAtIndex = (historyMoves, moveIndex) => {
  if (moveIndex >= 0 && moveIndex < historyMoves.length) {
    const { from, to } = historyMoves[moveIndex];
    setSquareStyles({
      [from]: { backgroundColor: 'rgba(255, 255, 0, 0.4)' },
      [to]: { backgroundColor: 'rgba(255, 255, 0, 0.6)' }
    });
  }
};

  // useEffect hook to update the highlight when the move index changes
  useEffect(() => {
    const historyMoves = chess.history({ verbose: true }); // Get the move history
    highlightMoveAtIndex(historyMoves, selectedMoveIndex); // Call the highlight function
  }, [selectedMoveIndex, chess]);

  const handleGameSelection = (index) => {
    setCurrentGameIndex(index);
    loadGame(games[index]); // Load the selected game
  };

  if (loading) {
    return <div>Loading board...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="replay-modal">
      <div className="board-modal-content">
        {/* Game Selector */}
        <div className="game-selector">
          <ul>
            {games.map((game, index) => {
              const whiteUsername = game.white?.username || extractUsernameFromUrl(game.white);
              const blackUsername = game.black?.username || extractUsernameFromUrl(game.black);

              return (
                <li
                  key={index}
                  onClick={() => handleGameSelection(index)}
                  className={`game-display-item ${index === currentGameIndex ? 'selected-game' : ''}`}
                >
                  <span className="white-player">{whiteUsername}</span>
                  <span className="vs-text">vs</span>
                  <span className="black-player">{blackUsername}</span>
                </li>
              );
            })}
          </ul>
        </div>

        {/* Close Button */}
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        <div className="game-layout">
          {/* Chessboard */}
          <ChessboardCustom
            position={fen} // Pass the current FEN
            onDrop={() => {}} // Custom drop logic, optional for view only
              orientation={
                (games[currentGameIndex]?.white?.username || extractUsernameFromUrl(games[currentGameIndex]?.white)) === userTeamPlayer
                  ? 'white'
                  : 'black'
              }
            squareStyles={squareStyles} // Apply square styling for last move
            degreeOfBlindness='Normal'
            isBoardDisplay={true}
          />

          {/* Render Move History */}
          <div className="move-history-container">
            {renderMoveHistory(
              {
                state: {
                  history: chess.history({ verbose: true }), // Use actual game history
                  initialFen: DEFAULT_START_FEN, // Use consistent start FEN
                  selectedMoveIndex: selectedMoveIndex,
                  mode: 'view',
                  isMoveTrainer: false
                },
                setFen, // Pass setFen to update chessboard position
                setSquareStyles, // Pass setSquareStyles for highlighting squares
                setSelectedMoveIndex // Update move index on move click
              },
              chess.history({ verbose: true }), // Consistently use chess history
              (data) => data
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardDisplay;
