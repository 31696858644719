import { useEffect } from "react";

const EnableScrollOnPage = () => {
  useEffect(() => {
    // Forcefully enable scroll by resetting overflow and height
    document.body.style.overflow = "auto";
    document.body.style.height = "auto";
    document.documentElement.style.overflow = "auto"; // Reset html element as well
    document.documentElement.style.height = "auto";

    return () => {
      // Optionally, reset any scroll restrictions upon component unmount (if needed for other pages)
      document.body.style.overflow = "";
      document.body.style.height = "";
      document.documentElement.style.overflow = "";
      document.documentElement.style.height = "";
    };
  }, []);

  return null; // No need to render anything
};

export default EnableScrollOnPage;
