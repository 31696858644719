import React from 'react';

function ThemeSelector({ currentTheme, setTheme }) {
  // Directly define the preview images here
//  console.log('Current theme: ' + currentTheme)
  const themePreviews = {
    royal: process.env.PUBLIC_URL + "/buttons/coronetTheme.png",
    tournament: process.env.PUBLIC_URL + "/buttons/tournamentTheme.png",
    brightAndLight: process.env.PUBLIC_URL + "/buttons/brightAndLight.png",
    darkAndMoody: process.env.PUBLIC_URL + "/buttons/darkAndMoody.png",
    freshAndNatural: process.env.PUBLIC_URL + "/buttons/freshAndNatural.png",
    classical: process.env.PUBLIC_URL + "/buttons/classical.png",
  };

return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '30px', justifyContent: 'center', alignItems: 'flex-start' }}>
      {Object.entries(themePreviews).map(([themeName, imageUrl]) => (
        <div key={themeName} style={{ width: '200px', textAlign: 'center' }}> {/* Parent container for each theme option */}
          <div style={{
            fontWeight: 'bold',
            marginBottom: '10px',
          }}>
            {themeName.charAt(0).toUpperCase() + themeName.slice(1).replace(/[A-Z]/g, ' $&')}
          </div>
          <div
            style={{
              border: currentTheme === themeName ? '4px solid #555' : '2px solid #ddd',
              borderRadius: '8px',
              overflow: 'hidden',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              transition: 'transform 0.3s ease',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add pop effect
            }}
            onClick={() => setTheme(themeName)}
            onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
            onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <img
              src={imageUrl}
              alt={themeName}
              style={{ width: '200px', height: '200px', objectFit: 'cover' }} // Adjust based on actual aspect ratio
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ThemeSelector;