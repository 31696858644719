import React from 'react';
import '../../styles/help.css'; // Reusing styles from help page
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Import the scroll enabler

const PrivacyPolicy = () => {
  return (
    <div className="help-container">
      {/* Enable scrolling on this page */}
      <EnableScrollOnPage />

      <h1>Privacy Policy for Train Chess</h1>

      <section>
        <h2>1. Introduction</h2>
        <p>
          Welcome to Train Chess! We respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines what data we collect, how we use it, and your rights regarding your personal information.
        </p>
      </section>

      <section>
        <h2>2. Information We Collect</h2>
        <ul>
          <li>
            <strong>Personal Information:</strong> When you register on our platform, we may collect personal information such as your name, email address, and username.
          </li>
          <li>
            <strong>Usage Data:</strong> We collect data about your interaction with Train Chess, such as the pages you visit, time spent on each page, and your preferences.
          </li>
          <li>
            <strong>Device Information:</strong> We may collect information about the device you use to access our services, including IP address, browser type, and operating system.
          </li>
        </ul>
      </section>

      <section>
        <h2>3. How We Use Your Information</h2>
        <ul>
          <li>
            <strong>To Provide Our Services:</strong> We use your information to create and manage your account, authenticate you when you log in, and deliver our services effectively.
          </li>
          <li>
            <strong>Improvement and Personalization:</strong> We analyze user activity to enhance our platform and provide content tailored to your preferences.
          </li>
          <li>
            <strong>Communication:</strong> We may send you service-related updates, notifications, or support-related information through email.
          </li>
        </ul>
      </section>

      <section>
        <h2>4. Sharing of Information</h2>
        <ul>
          <li>
            <strong>Service Providers:</strong> We use third-party services, such as Firebase and Google Analytics, to help provide our services. These third parties have access to your information only to perform tasks on our behalf and are obligated not to disclose or use it for other purposes.
          </li>
          <li>
            <strong>Legal Compliance:</strong> We may disclose your personal information if required to do so by law or in response to valid requests by public authorities.
          </li>
        </ul>
      </section>

      <section>
        <h2>5. Cookies and Tracking Technologies</h2>
        <p>
          We use cookies to track your activity on our website and enhance your user experience. You can disable cookies through your browser settings, but doing so may affect some functionalities of our platform.
        </p>
      </section>

      <section>
        <h2>6. Your Rights</h2>
        <ul>
          <li><strong>Access:</strong> You have the right to request a copy of the personal information we hold about you.</li>
          <li><strong>Correction:</strong> You may ask us to correct any inaccurate information.</li>
          <li><strong>Deletion:</strong> You can request the deletion of your personal data at any time.</li>
        </ul>
      </section>

      <section>
        <h2>7. Data Security</h2>
        <p>
          We take appropriate technical and organizational measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction.
        </p>
      </section>

      <section>
        <h2>8. Children's Privacy</h2>
        <p>
          Our platform is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13.
        </p>
      </section>

      <section>
        <h2>9. Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. Any changes will be posted on this page, and you will be notified of any significant changes through email or other means.
        </p>
      </section>

      <section>
        <h2>10. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or your data, please contact us at <a href="mailto:contact@trainchess.net">contact@trainchess.net</a>.
        </p>
      </section>

      <footer>
        <p><strong>Effective Date:</strong> November 6, 2024</p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
