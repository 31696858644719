import React from 'react';
import '../styles/AboutPage.css'; // Adjust the path according to your file structure
import EnableScrollOnPage from '../Integrations/EnableScroll'; // Import the scroll enabler

const About = () => {
    return (
        <div className="about-page">
            {/* Enable scrolling on this page */}
            <EnableScrollOnPage />
            <header>
                <h1>About</h1>
            </header>
            <main>
                <section>
                    <h2>Welcome to Train Chess</h2>
                    <p>Our mission is to provide some less available tools for chess improvement with a simple and free interface. Here, you can practice visualization (arguably the most important skill in the game) by learning to play blindfold chess. You can also practice pattern recognition by doing puzzles which target an 85% success rate (optimal for improvement).</p>
                </section>
                <section>
                    <h2>Attributions</h2>
                    <ul>
                        <li>Chess engine provided by <a href="https://github.com/official-stockfish/Stockfish">Stockfish</a>: The Stockfish developers (see AUTHORS file). Stockfish [Computer software]. </li>
                        <li>Puzzles sourced from <a href="https://database.lichess.org/#puzzles">Lichess</a></li>
                    </ul>
                </section>
                <section>
                    <h2>Additional Free Chess Training Resources</h2>
                    <ul>
                        <li><a href="https://chess-endgame-trainer.web.app/">Endgame Trainer</a></li>
                        <li><a href="https://chesstraining.app/">Chess Training Web App</a></li>
                    </ul>
                </section>
                <section>
                    <h2>Send Us Your Feedback</h2>
                    <p>Your feedback is valuable to us. Please let us know how we can improve by filling out our <a href="https://www.surveymonkey.com/r/7YCTMXC">feedback form</a>.</p>
                </section>
            </main>
            <footer>
                <p>© 2024, Train Chess. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default About;
