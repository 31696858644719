import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import ChessboardPreview from '../Integrations/ChessboardPreview';
import '../styles/LoadGame.css';
import EnableScrollOnPage from '../Integrations/EnableScroll'; // Import the scroll enabler

const db = getFirestore();

const LoadGame = () => {
  const [games, setGames] = useState([]);
  const [displayedGames, setDisplayedGames] = useState([]);
  const [page, setPage] = useState(0);
  const gamesPerPage = 5;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGames = async () => {
      const gamesQuery = query(collection(db, 'games'));
      const gamesSnapshot = await getDocs(gamesQuery);
      let gamesList = gamesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        updatedAt: doc.data().updatedAt?.toDate() // Store as Date object for comparison
      }));
      // Sort the games by updatedAt in descending order (most recent first)
      gamesList.sort((a, b) => b.updatedAt - a.updatedAt);
      setGames(gamesList);
    };

    fetchGames();
  }, []);

  useEffect(() => {
    // Slice the games to only show a limited number at a time
    setDisplayedGames(games.slice(0, (page + 1) * gamesPerPage));
  }, [games, page]);

  const handleGameSelection = (game) => {
    localStorage.setItem('selectedGame', JSON.stringify(game));
    navigate('/play', { state: { history: game.history } });
  };

  const loadMoreGames = () => {
    setPage(page + 1);  // Increase page to load more games
  };

  return (
    <div className="load-game-container">
      <EnableScrollOnPage />
      <h1 className="load-game-header">My Games</h1>
        {displayedGames.map(game => (
          <div
            key={game.id}
            onClick={() => handleGameSelection(game)}
            className="game-item"
          >
            <div className="game-details">
              <h2>Stockfish Level {game.stockfishLevel}</h2>
              <p>Last Played: {game.updatedAt.toLocaleString()}</p>
              <p>Player Color: {game.playerColor === 'white' ? 'White' : 'Black'}</p>
              <p>Blindfold Level: {game.blindfold}</p>
            </div>
            <div className="chessboard-preview-container">
              <ChessboardPreview fen={game.fen} playerColor={game.playerColor} />
            </div>
          </div>
        ))}
        {page * gamesPerPage < games.length && (
          <button className='puzzle-button' onClick={loadMoreGames}>Load More</button>
        )}
    </div>
  );
};

export default LoadGame;
