import React, { useState, useEffect, useContext } from 'react';
import { ThemesContext } from '../Integrations/ThemesContext'; // Adjust the path as needed
import ThemeSelector from '../Integrations/ThemeSelector';
import '../styles/Settings.css';
import EnableScrollOnPage from '../Integrations/EnableScroll'; // Import the scroll enabler

function Settings() {
  const { themeName, theme, setTheme, updateCustomTheme } = useContext(ThemesContext);
  const [customTheme, setCustomTheme] = useState({
    darkSquareColor: theme['--dark-square-color'],
    lightSquareColor: theme['--light-square-color'],
    normalPiece: theme['--normal-piece'],
    markerPiece: theme['--marker-piece'],
    primaryColor: theme['--primary-color'],
    highlightColor: theme['--highlight-color'],
    secondaryColor: theme['--secondary-color'],
    backgroundColor: theme['--background-color'],
    // Since highlightColorDark and highlightColorTrans are derived values,
    // consider calculating them dynamically in effects or as part of the rendering logic
    highlightColorDark: '',
    highlightColorTrans: '',
    textColor1: theme['--text-color1'],
    textColor2: theme['--text-color2'],
    font: theme['--font'],
  });

useEffect(() => {
  if (theme) {
    // Temporarily hold the updated theme settings
    console.log('Theme: ' + JSON.stringify(theme) + ' \nTheme dark square: ' + theme['--dark-square-color'])
    const updatedCustomTheme = {
      darkSquareColor: theme['--dark-square-color'] || '',
      lightSquareColor: theme['--light-square-color'] || '',
      normalPiece: theme['--normal-piece'] || '',
      markerPiece: theme['--marker-piece'] || '',
      primaryColor: theme['--primary-color'] || '',
      highlightColor: theme['--highlight-color'] || '',
      secondaryColor: theme['--secondary-color'] || '',
      backgroundColor: theme['--background-color'] || '',
      highlightColorDark: '', // To be conditionally updated
      highlightColorTrans: '', // To be conditionally updated
      textColor1: theme['--text-color1'] || '',
      textColor2: theme['--text-color2'] || '',
      font: theme['--font'] || '',
    };

    // Only calculate dark and transparent versions if base color is defined
    if (updatedCustomTheme.highlightColor) {
      updatedCustomTheme.highlightColorDark = darkenHexColor(updatedCustomTheme.highlightColor);
      updatedCustomTheme.highlightColorTrans = toHalfTransparentHex(updatedCustomTheme.highlightColor);
    }

    // Update the state with the new settings
    setCustomTheme(updatedCustomTheme);
    console.log('Theme' + customTheme.primaryColor)
  }
}, [theme]); // Depend on `theme` to trigger this effect

const applyCustomTheme = () => {
  const kebabCaseTheme = Object.keys(customTheme).reduce((acc, key) => {
    const kebabCaseKey = camelToKebab(key);
    acc[kebabCaseKey] = customTheme[key];
    return acc;
  }, {});

  updateCustomTheme(kebabCaseTheme); // Assuming this function expects kebab-case keys

  // Saving to localStorage as an example
  localStorage.setItem('customTheme', JSON.stringify(kebabCaseTheme));

  localStorage.removeItem('themeName');
};

// Convert camelCase to kebab-case and prepend with '--'
function camelToKebab(key) {
  return '--' + key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}


// Convert kebab-case to camelCase
//function kebabToCamel(key) {
//  return key.replace(/(-\w)/g, m => m[1].toUpperCase());
//}

function toHalfTransparentHex(hex) {
  // Ensure the hex code is in the format #RRGGBB
  if (hex.length === 4) {
    hex = '#' + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
  }
  // Append 80 to make it half transparent
  return hex + '80';
}

function darkenHexColor(hex, amount = 30) {
  // Convert hex to RGB
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  // Decrease each component by the amount to darken and clamp the values between 0 and 255
  r = Math.max(0, r - amount);
  g = Math.max(0, g - amount);
  b = Math.max(0, b - amount);

  // Convert back to hex
  const rHex = r.toString(16).padStart(2, '0');
  const gHex = g.toString(16).padStart(2, '0');
  const bHex = b.toString(16).padStart(2, '0');

  return `#${rHex}${gHex}${bHex}`;
}

const handleThemeChange = (event) => {
    const { name, value } = event.target;
    if (name === "highlightColor") {
        // When highlightColor changes, also update dark and transparent versions
        const newHighlightColorDark = darkenHexColor(value);
        const newHighlightColorTrans = toHalfTransparentHex(value);
        setCustomTheme(prevTheme => ({
            ...prevTheme,
            highlightColor: value,
            highlightColorDark: newHighlightColorDark,
            highlightColorTrans: newHighlightColorTrans,
        }));
    } else {
        // For other properties, update normally
        setCustomTheme(prevTheme => ({
            ...prevTheme,
            [name]: value,
        }));
    }
};

function getPieceSetPreviewImagePath(pieceSet) {
  const pieceSetImages = {
    'normal': `${process.env.PUBLIC_URL}/pieces/normal/wN.png`, // Replace with your image path
    'normal-bal': `${process.env.PUBLIC_URL}/pieces/normal-bal/wN.png`,
    'normal-modern': `${process.env.PUBLIC_URL}/pieces/normal-modern/wN.png`,
    'marker': `${process.env.PUBLIC_URL}/pieces/marker/w.png`,
    'marker-bal': `${process.env.PUBLIC_URL}/pieces/marker-bal/w.png`,
    'marker-dam': `${process.env.PUBLIC_URL}/pieces/marker-dam/w.png`,
    'marker-tree': `${process.env.PUBLIC_URL}/pieces/marker-tree/w.png`,
  };
  return pieceSetImages[pieceSet] || ''; // Return an empty string or a placeholder image path if not found
}


  return (
    <div style={{ padding: '20px' }}>
      <EnableScrollOnPage />
      <h1>Settings</h1>
      <h3>Choose a Theme:</h3>
      <ThemeSelector currentTheme={themeName} setTheme={setTheme} />
        <div className="theme-customization">
          <h3>Or Customize Your Own:</h3>
          <div className="color-picker-group">
            <div className="color-picker">
              <label>Theme Color:</label>
              <input type="color" name="primaryColor" value={customTheme.primaryColor} onChange={handleThemeChange} />
            </div>
            <div className="color-picker">
              <label>Accent Color:</label>
              <input type="color" name="highlightColor" value={customTheme.highlightColor} onChange={handleThemeChange} />
            </div>
            <div className="color-picker">
              <label>Background Color:</label>
              <input type="color" name="backgroundColor" value={customTheme.backgroundColor} onChange={handleThemeChange} />
            </div>
            <div className="color-picker">
              <label>Dark Square Color:</label>
              <input type="color" name="darkSquareColor" value={customTheme.darkSquareColor} onChange={handleThemeChange} />
            </div>
            <div className="color-picker">
              <label>Light Square Color:</label>
              <input type="color" name="lightSquareColor" value={customTheme.lightSquareColor} onChange={handleThemeChange} />
            </div>
          </div>
          <div className="selection-group">
            <div className="piece-set-selection">
              <label>Piece Set:</label>
              <select name="normalPiece" value={customTheme.normalPiece} onChange={handleThemeChange}>
                {/* Option values can be adjusted based on available piece sets */}
                <option value="normal">Default</option>
                <option value="normal-bal">Arctic</option>
                <option value="normal-modern">Dynasty</option>
              </select>
              {customTheme.normalPiece && (
                <div className="piece-set-preview">
                  <img src={getPieceSetPreviewImagePath(customTheme.normalPiece)} alt="Piece Set Preview" style={{maxWidth: '100%', height: 'auto', marginTop: '10px'}} />
                </div>
              )}
            </div>
            <div className="marker-selection">
              <label>Marker:</label>
              <select name="markerPiece" value={customTheme.markerPiece} onChange={handleThemeChange}>
                <option value="marker">Default</option>
                <option value="marker-bal">Sea Shells</option>
                <option value="marker-dam">Orb</option>
                <option value="marker-tree">Trees</option>
              </select>
              {customTheme.normalPiece && (
                <div className="piece-set-preview">
                  <img src={getPieceSetPreviewImagePath(customTheme.markerPiece)} alt="Piece Set Preview" style={{maxWidth: '100%', height: 'auto', marginTop: '10px'}} />
                </div>
              )}
            </div>
            <div className="font-style-selection">
              <label>Font Style:</label>
              <select name="font" value={customTheme.font} onChange={handleThemeChange}>
                {/* Add more font options as needed */}
                <option value="'Poppins', sans-serif">Poppins</option>
                <option value="'Montserrat', sans-serif">Montserrat</option>
                <option value="'Roboto', sans-serif">Roboto</option>
                <option value="'Playfair Display', serif">Playfair Display</option>
                <option value="'Merriweather', serif">Merriweather</option>
                <option value="'Open Sans', sans-serif">Open Sans</option>
              </select>
              <div className="font-preview" style={{ fontFamily: customTheme.font }}>
                 Welcome to Train Chess!
              </div>
            </div>
          </div>
          <button className='btn-1' onClick={applyCustomTheme}>Apply Custom Theme</button>
        </div>
      </div>
  );
}

export default Settings;
