import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/help.css'; // Ensure correct path to your CSS file
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Import the scroll enabler

const HelpPage = () => {
  return (
    <div className="help-container">
      <EnableScrollOnPage />
      <h1>Help</h1>
      <p>
        This page is your gateway to all the help pages for understanding how to get started with the resources on Train Chess.
      </p>

      {/* Pattern Recognition Section */}
      <section className="help-section">
        <h2>Pattern Recognition</h2>
        <ul>
          <li>
            <Link to="/puzzles/help" className="help-page-link">Tactics Trainer</Link>
          </li>
          <li>
            <Link to="/findthefork/help" className="help-page-link">Find the Fork</Link>
          </li>
        </ul>
      </section>

      {/* Visualization Section */}
      <section className="help-section">
        <h2>Visualization</h2>
        <ul>
          <li>
            <Link to="/playblindfold/help" className="help-page-link">Play Blindfold</Link>
          </li>
          <li>
            <Link to="/blindfoldpuzzles/help" className="help-page-link">Blindfold Puzzles</Link>
          </li>
          <li>
            <Link to="/calculationpuzzles/help" className="help-page-link">Calculation Puzzles</Link>
          </li>
          <li>
            <Link to="/coordinatetrainer/help" className="help-page-link">Coordinate Trainer</Link>
          </li>
        </ul>
      </section>



      {/* Other Resources Section */}
      <section className="help-section">
        <h2>Other Resources</h2>
        <ul>
          <li>
            <Link to="/ratingmetrics" className="help-page-link">Our Unique Rating System</Link>
          </li>
          <li>
            <Link to="/algebraicnotation" className="help-page-link">Learning Algebraic Notation</Link>
          </li>
        </ul>
      </section>

      {/* About Section for Contact and Website Info */}
      <section className="further-help">
        <h3>Need more help or want to learn more about us?</h3>
        <p>Visit our <Link to="/about" className="further-help-link">about page</Link> or <a href="/contact">contact us</a> with questions or comments.</p>
      </section>
    </div>
  );
};

export default HelpPage;

//      {/* Drills Section */}
//      <section className="help-section">
//        <h2>Theory</h2>
//        <ul>
//          <li>
//            <Link to="/endgames/help" className="help-page-link">Endgame Drills</Link>
//          </li>
//          {/* Add other Drills-related links as needed */}
//        </ul>
//      </section>
//
//      {/* Community Section */}
//      <section className="help-section">
//        <h2>Community</h2>
//        <ul>
//          <li>
//            <Link to="/announcements/help" className="help-page-link">Announcements</Link>
//          </li>
//          {/* Add other Community-related links as needed */}
//        </ul>
//      </section>