import React, { useEffect, useState } from 'react';
import { doc, getFirestore, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import '../styles/teamSelection.css';

const db = getFirestore();

const TeamSelection = ({ userDocRef, onSelectTeam }) => {
  const [goldCount, setGoldCount] = useState(0);
  const [blueCount, setBlueCount] = useState(0);
  const [goldWins, setGoldWins] = useState(0);
  const [blueWins, setBlueWins] = useState(0);

  useEffect(() => {
    const fetchTeamStats = async () => {
      try {
        const teamStatsDoc = await getDoc(doc(db, 'voteChessSettings', 'teamStats'));
        if (teamStatsDoc.exists()) {
          const { gold = 0, blue = 0, goldWins = 0, blueWins = 0 } = teamStatsDoc.data();
          setGoldCount(gold);
          setBlueCount(blue);
          setGoldWins(goldWins);
          setBlueWins(blueWins);
        }
      } catch (error) {
        console.error('Error fetching team stats:', error);
      }
    };

    fetchTeamStats();
  }, []);

  const handleTeamSelection = async (selectedTeam) => {
    try {
      await updateDoc(userDocRef, { team: selectedTeam });

      // Reference the teamStats document in Firestore
      const teamStatsDocRef = doc(db, 'voteChessSettings', 'teamStats');
      const teamStatsDoc = await getDoc(teamStatsDocRef);

      // If the teamStats document does not exist, create it with initial values
      if (!teamStatsDoc.exists()) {
        await setDoc(teamStatsDocRef, { gold: 0, blue: 0, goldWins: 0, blueWins: 0 });
      }

      // Update the team count
      await updateDoc(teamStatsDocRef, {
        [selectedTeam]: selectedTeam === 'gold' ? goldCount + 1 : blueCount + 1,
      });

      onSelectTeam(selectedTeam); // Notify parent component
    } catch (error) {
      console.error('Error saving team to Firestore:', error);
    }
  };

  return (
    <div className="team-selection-modal">
      <h3>Choose Your Team</h3>
      <div className="team-stats">
        <div className="team gold">
          <h4>Gold Team</h4>
          <p>{goldCount} members</p>
          <p>{goldWins} games won</p>
          <button onClick={() => handleTeamSelection('gold')}>Join Gold</button>
        </div>
        <div className="team blue">
          <h4>Blue Team</h4>
          <p>{blueCount} members</p>
          <p>{blueWins} games won</p>
          <button onClick={() => handleTeamSelection('blue')}>Join Blue</button>
        </div>
      </div>
    </div>
  );
};

export default TeamSelection;
