import { addDoc, collection, onSnapshot, getFirestore, query, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { FirebaseApp } from "firebase/app";
import { httpsCallable, getFunctions } from "firebase/functions";

// Updated getCheckoutUrl to receive Firebase app and priceId as arguments
export const getCheckoutUrl = async (app, checkoutData) => {
  console.log("Starting getCheckoutUrl function...");

  const auth = getAuth(app);
  const userId = auth.currentUser?.uid;

  if (!userId) {
    console.error("User is not authenticated. Exiting function.");
    throw new Error("User is not authenticated");
  }

  console.log("User is authenticated with UID:", userId);

  try {
    const db = getFirestore(app);
    const checkoutSessionRef = collection(db, "users", userId, "checkout_sessions");

    const docRef = await addDoc(checkoutSessionRef, {
      ...checkoutData // Spread the checkoutData object
    });
    console.log("Checkout session document created with ID:", docRef.id);

    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(docRef, (snap) => {
        console.log("Received snapshot update for checkout session document.");

        const data = snap.data();
        if (!data) {
          console.warn("No data found in the snapshot.");
          return;
        }

        const { error, url } = data;

        if (error) {
          console.error("Error in checkout session data:", error.message);
          unsubscribe();
          reject(new Error(`An error occurred: ${error.message}`));
        } else if (url) {
          console.log("Stripe Checkout URL received:", url);
          unsubscribe();
          resolve(url);
        } else {
          console.log("Waiting for the URL field to be populated in Firestore document...");
        }
      });
    });
  } catch (error) {
    console.error("Error creating checkout session document in Firestore:", error);
    throw error;
  }
};

export const getPortalUrl = async (user) => {
  if (!user) {
    console.error("User is not authenticated. Exiting function.");
    throw new Error("User is not authenticated");
  }

  console.log("User is authenticated with UID:", user.uid);

  try {
    const functions = getFunctions(); // Initializes Firebase Functions
    const createPortalLink = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );

    // Call the Cloud Function with the customer ID and return URL
    const response = await createPortalLink({
      customerId: user.uid,
      returnUrl: window.location.origin + "/managesubscription", // URL to return the user after managing the subscription
    });

    const data = response.data;

    // Validate the returned data
    if (data && data.url) {
      console.log("Successfully retrieved Stripe customer portal URL:", data.url);
      return data.url; // Return the portal URL
    } else {
      console.error("No URL returned from the createPortalLink function");
      throw new Error("No URL returned from the function");
    }
  } catch (error) {
    console.error("Error fetching the portal URL:", error);
    throw new Error("Failed to get the Stripe customer portal URL");
  }
};


export const getPremiumStatus = async (user) => {
    if (!user) throw new Error("User not logged in");

    const db = getFirestore();
    const subscriptionsRef = collection(db, "users", user.uid, "subscriptions");
    const q = query(
        subscriptionsRef,
        where("status", "in", ["trialing", "active"])
    );

    return new Promise((resolve, reject) => {
        const unsubscribe = onSnapshot(
            q,
            (snapshot) => {
                if (snapshot.docs.length === 0) {
                    console.log("No active or trialing subscriptions found");
                    resolve(false);
                } else {
                    console.log("Active or trialing subscription found");
                    resolve(true);
                }
                unsubscribe();
            },
            reject
        );
    });
};
