export const openingBook = {
  // Initial position
  "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1": ["e4", "d4", "c4", "Nf3", "e4", "d4", "c4", "Nf3", "e4", "d4", "c4", "Nf3", 'g3', 'g4', 'f4', 'b3', 'd3', 'e3', 'c3'],

// 1. e4 openings ########################################################################################################
  "rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR b KQkq - 0 1": ["c5", "e5", "e6", "c6", "g6"],
  // Sicilian Defense
  "rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2": ["Nf3", "d4", "Nc3", "Bc4", 'c3'],
    // Smith Morra
    'rnbqkbnr/pp1ppppp/8/2p5/3PP3/8/PPP2PPP/RNBQKBNR b KQkq - 0 2': ['d5', 'cxd4', 'e6'],
  // French Defense
  "rnbqkbnr/ppp1pppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq - 0 2": ["d4", "Nc3"],

// 1. d4 openings ########################################################################################################
  "rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR b KQkq - 0 1": ["d5", "Nf6", "e6", 'e5', 'c6', "Nc6"],
  // Queen's Gambit
  "rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR w KQkq - 0 2": ["c4", "Nc3"],
  // King's Indian Defense
  "rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq - 0 2": ["c4", "Nc3"],
  // London
  'rnbqkbnr/ppp1pppp/8/3p4/3P1B2/8/PPP1PPPP/RN1QKBNR b KQkq - 1 2': ['Nf6', 'e6', 'c5', 'Bf5', 'Bg4', 'c6'],

// 1. c4 openings ########################################################################################################
"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR b KQkq - 0 1": ["e5", "Nf6", "c5", 'd5', 'Nc6', 'd6', 'g6'],

// 1. Nf3 openings ########################################################################################################
"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R b KQkq - 1 1": ["d5", "Nf6", "g6", 'c5', 'd6', 'e6', 'g6', 'b6', 'Nc6'],

// 1. d3 openings ########################################################################################################
"rnbqkbnr/pppppppp/8/8/8/3P4/PPP1PPPP/RNBQKBNR b KQkq - 0 1": ["e5", "d5", "Nf6", "c5", 'g6', 'b6', "Nc6"],

// 1. g3 openings ########################################################################################################
"rnbqkbnr/pppppppp/8/8/8/6P1/PPPPPP1P/RNBQKBNR b KQkq - 0 1": ["d5", "Nf6", "c5", 'e5', 'Nc6', 'g6', 'h5'],

// 1. b3 openings ########################################################################################################
"rnbqkbnr/pppppppp/8/8/8/1P6/P1PPPPPP/RNBQKBNR b KQkq - 0 1": ["e5", "d5", "Nf6", 'a5', 'c5', 'Nc6'],

// 1. f4 openings ########################################################################################################
"rnbqkbnr/pppppppp/8/8/5P2/8/PPPPP1PP/RNBQKBNR b KQkq - 0 1": ["d5", "e5", "Nf6", "d5", "e5", "Nf6", "f5", 'Nc6', 'c5', 'b3', 'g3', 'g5'],

// 1. a3 openings ########################################################################################################
"rnbqkbnr/pppppppp/8/8/8/P7/1PPPPPPP/RNBQKBNR b KQkq - 0 1": ["d5", "e5", "c5", 'f5', 'g6', 'b6'],

// 1. h4 openings ########################################################################################################
"rnbqkbnr/pppppppp/8/8/7P/8/PPPPPPP1/RNBQKBNR b KQkq - 0 1": ["d5", "e5", "Nf6", 'h5'],

// 1. g4 openings ########################################################################################################
"rnbqkbnr/pppppppp/8/8/6P1/8/PPPPPP1P/RNBQKBNR b KQkq - 0 1": ["d5", "Bg7", "h6", 'd6', 'e6', 'e5', "Nf6"],

// 1. c3 openings ########################################################################################################
"rnbqkbnr/pppppppp/8/8/8/2P5/PP1PPPPP/RNBQKBNR b KQkq - 0 1": ["d5", 'e5', "Nf6", "c6", "Nc6"]

};