import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { Chess } from 'chess.js';
import { db } from '../firebase-config';

export const quotes = [
    "\"All I want to do, ever, is just play Chess.\" — Bobby Fischer",
    "\"Chess is life.\" — Bobby Fischer",
    "\"A bad day of Chess is better than any good day at work.\" — Anonymous",
    "\"Chess is imagination.\" — David Bronstein",
    "\"No one ever won a game by resigning.\" — Savielly Tartakover",
    "\"When in doubt... play Chess!\" — Tevis",
    "\"Chess is mental torture.\" — Garry Kasparov",
    "\"Play the opening like a book, the middle game like a magician, and the endgame like a machine.\" — Spielmann",
    "\"Every Chess master was once a beginner.\" — Chernev",
    "\"Chess is above all, a fight!\" — Emanuel Lasker",
    "\"The King is a fighting piece. Use it!\" — Wilhelm Steinitz",
    "\"Chess is 99 percent tactics.\" — Teichmann",
    "\"You can only get good at Chess if you love the game.\" — Bobby Fischer",
    "\"Chess makes man wiser and clear-sighted.\" — Vladimir Putin",
    "\"Help your pieces so they can help you.\" — Paul Morphy",
    "\"Chess is the art of analysis.\" — Mikhail Botvinnik",
    "\"A passed Pawn increases in strength as the number of pieces on the board diminishes.\" — Capablanca",
    "\"Chess is a sea in which a gnat may drink and an elephant may bathe.\" — Hindu proverb",
    "\"The mistakes are there, waiting to be made.\" — Savielly Tartakover",
    "\"The blunders are all there on the board, waiting to be made.\" — Savielly Tartakover",
    "\"Chess is war over the board. The object is to crush the opponent's mind.\" — Bobby Fischer",
    "\"The hardest game to win is a won game.\" — Emanuel Lasker",
    "\"In life, as in Chess, forethought wins.\" — Charles Buxton",
    "\"Chess is the gymnasium of the mind.\" — Blaise Pascal",
    "\"Excellence at Chess is one mark of a scheming mind.\" — Arthur Conan Doyle",
    "\"Chess is a beautiful mistress.\" — Bent Larsen",
    "\"The beauty of a move lies not in its appearance but in the thought behind it.\" — Aaron Nimzovich",
    "\"The game of Chess is not just idle amusement; it is training of the mind for battle.\" — Benjamin Franklin",
    "\"Chess, like love, like music, has the power to make people happy.\" — Siegbert Tarrasch",
    "\"Openings teach you openings. Endgames teach you chess.\" — Stephan Gerzadowicz",
    "\"The primary constraint on a piece's activity is the Pawn structure.\" — Michael Stean",
    "\"Chess is a fighting game which is purely intellectual and includes chance.\" — Richard Reti",
    "\"You may learn much more from a game you lose than from a game you win.\" — José Raúl Capablanca",
    "\"I always see a little chessboard in my mind with every move in its place.\" — Marcel Duchamp",
    "\"To avoid losing a piece, many a person has lost the game.\" — Savielly Tartakower",
    "\"Chess is everything: art, science, and sport.\" — Anatoly Karpov",
    "\"In chess, as in life, one is always trying to force the other into a compromise.\" — Charles Buxton",
    "\"Every Pawn is a potential Queen.\" — James Mason",
    "\"Even a poor plan is better than no plan at all.\" — Mikhail Chigorin",
    "\"The passed Pawn is a criminal, who should be kept under lock and key.\" — Aron Nimzowitsch",
    "\"Chess is not always about winning. Sometimes it's simply about learning.\" — Anonymous",
    "\"When you see a good move, look for a better one.\" — Emanuel Lasker",
    "\"It is always better to sacrifice your opponent's men.\" — Savielly Tartakower",
    "\"No price is too great for the scalp of the enemy King.\" — Koblentz",
    "\"Chess, like any creative activity, can exist only through the combined efforts of those with creative talent.\" — Mikhail Botvinnik",
    "\"Half the variations which are calculated in a tournament game turn out to be completely superfluous.\" — Jan Timman",
    "\"Chess is as much a mystery as women.\" — Purdy",
    "\"Discovered check is the dive bomber of the Chessboard.\" — Reuben Fine",
    "\"The King is the weakest piece on the board; the King is the strongest piece on the board.\" — Anonymous",
    "\"Different people feel differently about resigning.\" — Bobby Fischer",
    "\"When the Chess game is over, the Pawn and the King go back to the same box.\" — Irish Saying",
    "\"No one ever won a game by resigning.\" — Savielly Tartakower",
    "\"Chess demands total concentration.\" — Bobby Fischer",
    "\"Chess is a struggle against error.\" — Johannes Zukertort",
    "\"The sign of a great master is his ability to win a won game quickly and painlessly.\" — Irving Chernev",
    "\"Chess is a terrific way for kids to build self-image and self-esteem.\" — Saudin Robovic",
    "\"I have always a slight feeling of pity for the man who has no knowledge of Chess\" - Siegbert Tarrasch",
    "\"We like to think\" - Garry Kasparov"
];

export const fetchPuzzleStats = async (user, timeLimit, isEndgame, setPuzzleRating, setPuzzleAccuracy, setPuzzleCount) => {
  // Default rating when the user is not logged in
  const defaultPuzzleStats = { rating: 1000, accuracy: 0, count: 0 };

  if (!user) {
    // No user logged in, set default rating and return it
    setPuzzleRating(defaultPuzzleStats.rating);
    console.log('No user logged in, using default rating:', defaultPuzzleStats.rating);
    return defaultPuzzleStats.rating; // Return the default rating
  }

  const userRef = doc(db, "users", user.uid);
  const userDoc = await getDoc(userRef);

  if (userDoc.exists()) {
    const data = userDoc.data();

    // Determine which field to fetch based on time limit and endgame filter
    let ratingKey;
//    if (isEndgame) {
    if (false) {
      switch (timeLimit) {
        case 5:
          ratingKey = "fiveSecondEndgameStats";
          break;
        case 10:
          ratingKey = "tenSecondEndgameStats";
          break;
        case 30:
          ratingKey = "thirtySecondEndgameStats";
          break;
        case 60:
          ratingKey = "oneMinuteEndgameStats";
          break;
        default:
          ratingKey = "noTimeLimitEndgameStats";
          break;
      }
    } else {
      switch (timeLimit) {
        case 5:
          ratingKey = "fiveSecondPuzzleStats";
          break;
        case 10:
          ratingKey = "tenSecondPuzzleStats";
          break;
        case 30:
          ratingKey = "thirtySecondPuzzleStats";
          break;
        case 60:
          ratingKey = "oneMinutePuzzleStats";
          break;
        default:
          ratingKey = "noTimeLimitPuzzleStats";
          break;
      }
    }

    // Fetch the rating data based on the key
    const puzzleStats = data[ratingKey] || "r1000a0c0"; // Default to "r1000a0c0" if not found
    const { rating, accuracy, count } = decodePuzzleStats(puzzleStats);

    // Set the rating, accuracy, and count to state
    setPuzzleRating(rating);
    setPuzzleAccuracy(accuracy);
    setPuzzleCount(count);

    return rating; // Return the fetched rating
  } else {
    // If the user document doesn't exist, fallback to default rating and return it
    setPuzzleRating(defaultPuzzleStats.rating);
    return defaultPuzzleStats.rating; // Return the default rating
  }
};


// Helper function to decode puzzleStats string into individual values
const decodePuzzleStats = (puzzleStats) => {
  const rating = parseInt(puzzleStats.match(/r(\d+)/)[1], 10); // Extract rating
  const accuracy = parseFloat(puzzleStats.match(/a([\d.]+)/)[1]); // Extract accuracy
  const count = parseInt(puzzleStats.match(/c(\d+)/)[1], 10); // Extract puzzle count

  return { rating, accuracy, count };
};

export const fetchPuzzle = async (puzzleRating, setCurrentPuzzle, setLoadingPuzzle, loadingPuzzleRef, isEndgame) => {
  if (puzzleRating === undefined || loadingPuzzleRef.current) return;
  loadingPuzzleRef.current = true;
  setLoadingPuzzle(true);

  try {
    // Fetch a random chunk of puzzles
    const chunkNumber = Math.floor(Math.random() * 811) + 1;
    const url = `${process.env.PUBLIC_URL}/calculationpuzzles/puzzles_chunk_${chunkNumber}.json`;

    const response = await fetch(url);
    if (!response.ok) throw new Error('Network response was not ok');

    const puzzles = await response.json();

    // Filter puzzles based on rating
    let filteredPuzzles = puzzles.filter(puzzle => Math.abs(puzzle.Rating - puzzleRating) <= 50);

    // If endgame filter is active, filter to puzzles with 7 pieces or less
    if (isEndgame) {
      filteredPuzzles = filteredPuzzles.filter(puzzle => {
        const newGame = new Chess(puzzle.FEN); // Initialize Chess.js with the puzzle's FEN
        const pieceCount = countPieces(newGame.board()); // Count the number of pieces on the board
        return pieceCount <= 9; // Endgames are generally considered to have 7 or fewer pieces
      });
    }

    if (filteredPuzzles.length > 0) {
      const selectedPuzzle = filteredPuzzles[Math.floor(Math.random() * filteredPuzzles.length)];

      // Apply LeadingMoves if available
      if (selectedPuzzle.LeadingMoves) {
        const newGame = new Chess(selectedPuzzle.FEN);
        const leadingMoves = selectedPuzzle.LeadingMoves.split(' ');

        leadingMoves.forEach(move => {
          const from = move.substring(0, 2);
          const to = move.substring(2, 4);
          const promotion = move.length === 5 ? move[4] : undefined;
          newGame.move({ from, to, promotion });
        });

        selectedPuzzle.FEN = newGame.fen(); // Update FEN after leading moves
      }

      setCurrentPuzzle(selectedPuzzle); // Set the puzzle with updated FEN
    } else {
      console.log('No puzzles found within the rating range or matching the endgame filter');
    }
  } catch (error) {
    console.error('Failed to load puzzle data:', error);
  } finally {
    loadingPuzzleRef.current = false;
    setLoadingPuzzle(false);
  }
};

// Helper function to count the number of pieces on the board
const countPieces = (board) => {
  let pieceCount = 0;
  board.forEach(row => {
    row.forEach(square => {
      if (square !== null) pieceCount += 1; // Each non-null square is a piece
    });
  });
  return pieceCount;
};

  export const toggleEndgame = (setIsEndgame) => {
    setIsEndgame(prevIsEndgame => !prevIsEndgame); // Toggle the state
  };