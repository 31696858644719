import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, GoogleAuthProvider, sendPasswordResetEmail, signInWithPopup, signInWithCredential } from "firebase/auth";
import '../firebase-config';
import '../styles/Credentials.css';
import EnableScrollOnPage from '../Integrations/EnableScroll'; // Import the scroll enabler
import { gapi } from 'gapi-script';
import { quotes } from '../Integrations/helperFunctions';

const auth = getAuth();

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const [shuffledQuotes, setShuffledQuotes] = useState([]);
    const [quoteIndex, setQuoteIndex] = useState(0);
    const [isFadingOut, setIsFadingOut] = useState(false);

  const initializeGapi = () => {
    gapi.client.init({
      clientId: '110322528888-d6fg9pg0tm9gqqrs44o4rhrjulajd9lq',
      scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
    });
  };

  useEffect(() => {
    // Load and initialize Google API
    gapi.load('client:auth2', initializeGapi);
  }, []);

    useEffect(() => {
        const shuffled = [...quotes].sort(() => Math.random() - 0.5);
        setShuffledQuotes(shuffled);
    }, []);

    // Carousel logic to change the quote every 5 seconds
    useEffect(() => {
        if (shuffledQuotes.length === 0) return;

        const interval = setInterval(() => {
            setIsFadingOut(true);
            setTimeout(() => {
                setQuoteIndex((prevIndex) => (prevIndex + 1) % shuffledQuotes.length);
                setIsFadingOut(false);
            }, 1000); // Allow some time for the fade-out to occur
        }, 6000); // Change quote every 5 seconds

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, [shuffledQuotes]);

    // Handle Firebase auth state changes
    useEffect(() => {
        console.log("Setting up onAuthStateChanged listener...");
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                console.log("User is logged in via onAuthStateChanged:", currentUser);
                navigate('/'); // Navigate to home after sign-in
            } else {
                console.log("No user is logged in via onAuthStateChanged.");
            }
        });
        return unsubscribe;
    }, [navigate]);

    // Handle traditional email/password login
    const signIn = async (event) => {
        event.preventDefault();
        console.log("Attempting to sign in with email and password...");
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            console.log('User signed in successfully:', userCredential.user);
            setErrorMessage('');
            navigate('/'); // Redirect to home after successful sign-in
        } catch (error) {
            console.error('Error signing in with email and password:', error.message);
            setErrorMessage(error.message);
        }
    };

    // Handle Google Sign-In with Popup
    const googleSignIn = async () => {
        try {
            const auth2 = gapi.auth2.getAuthInstance();
            const googleUser = await auth2.signIn();

            // Get the ID token from Google
            const idToken = googleUser.getAuthResponse().id_token;

            // Use Firebase `signInWithCredential()` to authenticate with Firebase
            const credential = GoogleAuthProvider.credential(idToken);
            const result = await signInWithCredential(auth, credential);
            console.log('Google sign-in successful:', result.user);

            navigate('/'); // Redirect to home
        } catch (error) {
            console.error('Error during Google sign-in:', error.message);
            setErrorMessage(error.message);
        }
    };

    // Handle password reset
    const resetPassword = async () => {
        if (!email) {
            setErrorMessage("Please enter your email first.");
            console.log('Password reset failed. Email field was empty.');
            return;
        }

        try {
            console.log('Sending password reset email to:', email);
            await sendPasswordResetEmail(auth, email);
            setSuccessMessage("Password reset email sent to entered email if an account exists. Please check your inbox.");
            setErrorMessage(''); // Clear error messages if any
            console.log('Password reset email sent successfully.');
        } catch (error) {
            console.error('Error sending password reset email:', error.message);
            setErrorMessage(error.message);
        }
    };

    return (
        <div className="split-screen-container">
            <EnableScrollOnPage />
            {/* Left Side */}
            <div className="split-left">
                <div className="overlay-credentials">
                    <img src="/checkeredBackground.png" alt="Sign Up Illustration" className="background-img" />
                    <div className="logo-container">
                        <img src="/logo.png" alt="Train Chess Logo" className="logo-img" />
                    </div>
                    <div className="quote-carousel">
                        <p className={`quote-text ${isFadingOut ? 'fade-out' : 'fade-in'}`}>
                            {shuffledQuotes[quoteIndex]}
                        </p>
                    </div>
                </div>
            </div>

            {/* Right Side */}
            <div className="split-right">
                <form onSubmit={signIn} className="signup-form">
                    <div className="signup-container">
                        <h2>Welcome back to <span className="highlight-text">Train Chess</span></h2>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="E-mail address"
                            autoComplete="email"
                            required
                        />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            autoComplete="current-password"
                            required
                        />
                        <button type='submit' className='btn-signup'>Log in</button>

                        <div className="divider">
                            <span>--- or ---</span>
                        </div>

                        <div className="social-login">
                            <button onClick={googleSignIn} className="btn-google-signup">
                                <img src="/googleIcon.png" alt="Google Icon" /> Continue with Google
                            </button>
                            <p style={{ color: '#333', marginTop: '10px', alignSelf: 'center' }}>
                                <a href="#" onClick={resetPassword} style={{ color: '#333' }}>Forgot your password?</a>
                            </p>
                        </div>
                        <p className="signup-link">
                            Don't have an account? <a href="/signup" style={{ color: '#333' }}>Sign up</a>
                        </p>
                        {errorMessage && <div className="error-message">{errorMessage}</div>}
                        {successMessage && <div className="success-message">{successMessage}</div>}
                    </div>
                </form>
            </div>
        </div>
    );
}
