import React from 'react';
import '../../styles/help.css'; // Adjust the path as per your structure
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Scroll enabler if needed

const RatingMetrics = () => {
  return (
    <div className="help-container">
      {/* Enable scrolling on this page */}
      <EnableScrollOnPage />

      <h1>Our Unique Rating System to Optimize Learning</h1>

      <section className="intro-section">
        <p>At <strong>TrainChess.net</strong>, we’ve developed a unique rating system specifically designed for puzzle solving. Unlike traditional Elo-based systems, which aim for a 50% success rate in competitive games, our system is focused on improving your skills through targeted difficulty. Here, your goal is to aim for an <strong>85% success rate</strong>—the scientifically supported sweet spot for effective learning.</p>
      </section>

      <section className="rating-accuracy-section">
        <h2>Key Metrics: Rating & Accuracy</h2>

        <h3>How TrainChess.net’s Rating System Differs</h3>
        <p>Most rating systems, like the Elo rating used in competitive chess, aim to create fair matches between two players. This means the ideal accuracy in such systems hovers around 50%, as players are matched with opponents of roughly equal skill. This is great for competitive play but not ideal for learning or puzzle-solving, where the goal is to gradually improve through deliberate practice.</p>
        <p><strong>TrainChess.net’s rating system</strong> is different. Instead of aiming for a 50% success rate, our puzzles are designed to adapt dynamically to your skill level and encourage an 85% accuracy rate. This ensures that you’re solving puzzles that are challenging but not overwhelming, which maximizes learning and helps you reach your potential faster.</p>

        <h3>What is Your Rating?</h3>
        <p>On TrainChess.net, your <strong>Rating</strong> reflects the difficulty level of the puzzles you are solving. As you successfully solve more challenging puzzles, your rating increases. If you find certain puzzles too difficult and frequently miss the correct solutions, your rating will adjust downward to keep the difficulty appropriate to your current skill level.</p>

        <p>Unlike competitive Elo ratings, this dynamic system allows you to remain within the optimal learning zone—where puzzles are difficult enough to push your thinking but not so hard that you get discouraged. As your rating adapts, you’ll always be tackling puzzles that are within reach but still require focused effort.</p>

        <h3>How is Accuracy Calculated?</h3>
        <p><strong>Accuracy</strong> measures how frequently you solve puzzles correctly over your last 100 attempts. It reflects both your short-term performance and long-term consistency, helping you track improvement over time.</p>
        <ul>
          <li><strong>Accuracy (Percentage):</strong> The percentage of puzzles you solved correctly out of the last 100 attempts.</li>
          <li><strong>Puzzle Count:</strong> The total number of puzzles you have attempted, giving you a sense of how much practice you’ve done overall.</li>
        </ul>
      </section>

      <section className="85-percent-rule">
        <h2>The 85% Rule: Why It's Ideal for Learning</h2>
        <p>Research shows that the optimal learning success rate is around 85%, meaning that you should aim to solve roughly 85% of the puzzles you attempt. Why? Because this balance between success and challenge keeps you engaged, encourages consistent improvement, and ensures that you’re working just beyond your current abilities.</p>

        <h3>Why a 50% Success Rate Isn’t Ideal for Learning</h3>
        <p>In traditional Elo-based systems, a 50% success rate is designed for competitive balance between players of equal skill. But this approach isn't ideal for learning because it means you're often facing challenges that are too difficult to consistently improve on. With a lower success rate, frustration builds, and the opportunity for learning diminishes.</p>

        <p>In contrast, TrainChess.net focuses on <strong>progressive learning</strong>. Aiming for an 85% success rate means you're solving puzzles that are in your “zone of proximal development”—puzzles that are slightly beyond what you’ve already mastered but not so difficult that they become frustrating. This ensures you’re constantly tackling low-hanging fruit—concepts that are within reach but haven’t yet received enough focus.</p>

        <h3>How the 85% Rule Drives Improvement</h3>
        <p>Here’s why aiming for 85% success rate works so well for learning:</p>
        <ul>
          <li><strong>Focus on Reachable Skills:</strong> By practicing puzzles slightly above your current level, you reinforce concepts you are close to mastering and push yourself just enough to make steady progress.</li>
          <li><strong>Encourages Active Learning:</strong> Striving for 85% means you are actively engaged, processing new information, and refining your strategies. You're always learning without feeling overwhelmed by failure.</li>
          <li><strong>Promotes Long-Term Retention:</strong> Research shows that learning happens most effectively when the task is neither too easy nor too hard. At 85%, you're optimizing both short-term understanding and long-term retention.</li>
        </ul>
        <p>For a deeper dive into the science behind the 85% rule, check out this <a href="https://www.nature.com/articles/s41467-019-12552-4" target="_blank" rel="noopener noreferrer">research study</a> published in <em>Nature Communications</em>.</p>
      </section>

      <section className="how-to-improve-section">
        <h2>How to Use These Metrics to Improve</h2>
        <p>Really, you don't need to do anything other than attempt puzzles as normal. In the background our algorithm will automatically target puzzles at the correct difficulty for <strong>you!</strong></p>
        <p>With this approach, you're continually working on the "low-hanging fruit"—puzzles that are just within reach, giving you a steady stream of wins and challenges that keep you learning.</p>
      </section>


    </div>
  );
};

export default RatingMetrics;

//      <section className="related-topics-section">
//        <h2>Related Topics</h2>
//        <p>Want to dive deeper? Check out these related topics:</p>
//        <ul>
//          <li><a href="/puzzle-solving-tips">Puzzle Solving Strategies</a></li>
//          <li><a href="/chess-tactics-guide">Chess Tactics Guide</a></li>
//          <li><a href="/mental-stamina">Building Mental Stamina</a></li>
//        </ul>
//      </section>