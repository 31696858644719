import React from 'react';
import '../../styles/help.css'; // Ensure the path matches your folder structure
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Import the scroll enabler

const PlayBlindfoldHelp = () => {
  return (
    <div className="help-container">
      {/* Enable scrolling on this page */}
      <EnableScrollOnPage />

      <h1>Play Blindfold</h1>

      <section className="intro-section">
        <p><a href="/play">Blindfold chess</a> is a variant of chess where you play without seeing the pieces on the board. Instead, you visualize the entire game in your mind, relying on memory and spatial awareness to track the pieces' movements and positions. There are many benefits to learning to play blindfold chess, but the problem is it can be extremely difficult. This tool is designed to help you progress from playing standard chess to playing blindfold chess.</p>
      </section>

<section className="blindfold-levels">
  <h2>The Six Levels of Blindfold</h2>
  <p>You can play with six levels of blindfold, each sequentially offering a diminishing amount of visual information on the board. When you begin a game, you are prompted with several settings, including blindfold level:</p>

  <div className="screenshot-container">
    <img src={`${process.env.PUBLIC_URL}/help/blindfoldLevels.png`} alt="Blindfold Levels" className="screenshot-small" />
  </div>

  <h3>1. Normal</h3>
  <p>
    Denoted by the
    <img
      src={`${process.env.PUBLIC_URL}/buttons/normal.png`}
      alt="icon"
      className="help-icon"
    />
    icon. You will see the chessboard as usual with black and white colored pieces.
  </p>
  <div className="screenshot-container">
    <img src={`${process.env.PUBLIC_URL}/help/normal.png`} alt="Blindfold Levels" className="screenshot" />
  </div>

  <h3>2. Normal Colorless</h3>
  <p>
    At this level,
    <img
      src={`${process.env.PUBLIC_URL}/buttons/normalColorless.png`}
      alt="icon"
      className="help-icon"
    />
    the piece shapes are still seen, but their colors are removed, forcing you to keep track of which pieces are your own and which are your opponents.
  </p>
  <div className="screenshot-container">
    <img src={`${process.env.PUBLIC_URL}/help/normalColorless.png`} alt="Blindfold Levels" className="screenshot" />
  </div>

  <h3>3. Markers</h3>
  <p>
    This mode
    <img
      src={`${process.env.PUBLIC_URL}/buttons/marker.png`}
      alt="icon"
      className="help-icon"
    />
    only shows generic black and white marker icons in place of the pieces. You’ll need to recall the type of piece on each square without seeing its specific shape.
  </p>
  <div className="screenshot-container">
    <img src={`${process.env.PUBLIC_URL}/help/markers.png`} alt="Blindfold Levels" className="screenshot" />
  </div>

  <h3>4. Marker Colorless</h3>
  <p>
    Here,
    <img
      src={`${process.env.PUBLIC_URL}/buttons/markerColorless.png`}
      alt="icon"
      className="help-icon"
    />
    the pieces are replaced by markers of the same color. This level tests your ability to track the positions of pieces using only neutral markers. You must remember the piece type and color.
  </p>
  <div className="screenshot-container">
    <img src={`${process.env.PUBLIC_URL}/help/markerColorless.png`} alt="Blindfold Levels" className="screenshot" />
  </div>

  <h3>5. Board Only</h3>
  <p>
    In the "Board Only" level,
    <img
      src={`${process.env.PUBLIC_URL}/buttons/boardOnly.png`}
      alt="icon"
      className="help-icon"
    />
    no pieces are visible. You must remember where all the pieces are located based purely on your memory of the previous moves. However, you can still see the geometry of the board to help you visualize piece movements.
  </p>
  <div className="screenshot-container">
    <img src={`${process.env.PUBLIC_URL}/help/boardOnly.png`} alt="Blindfold Levels" className="screenshot" />
  </div>

  <h3>6. Blindfold</h3>
  <p>
    In this final mode,
    <img
      src={`${process.env.PUBLIC_URL}/buttons/blindfold.png`}
      alt="icon"
      className="help-icon"
    />
    both the board and the pieces are entirely invisible. You are playing blindfold chess! You’ll play purely from memory, visualizing both the board and the pieces as you would in a real blindfolded game. This is the ultimate test of board visualization.
  </p>

  <div className="screenshot-container">
    <img src={`${process.env.PUBLIC_URL}/help/blindfold.png`} alt="Blindfold Mode" className="screenshot" />
  </div>
  <p>
    <strong>Note: </strong> When playing full blindfold chess, you will need to enter your moves in <a href="/algebraicnotation">algebraic notation</a> in the move input container underneath the board. You can also use this method when playing other modes, but you will also have the option of clicking or dragging pieces.
  </p>
  <div className="screenshot-container">
    <img src={`${process.env.PUBLIC_URL}/help/moveInput.png`} alt="Blindfold Mode" className="screenshot-small" />
  </div>
  <p>
    <strong>Peek Button: </strong> If you forget where the pieces are, there is a "Peek" button available. Clicking this button temporarily reveals the board with normal pieces so you can remind yourself of the piece positions before continuing the game in blindfold mode.
  </p>
  <div className="screenshot-container">
    <img src={`${process.env.PUBLIC_URL}/help/peekButton.png`} alt="Blindfold Mode" className="screenshot-small" />
  </div>

</section>

<section className="board-awareness-quizzes">
  <h2>Board Awareness Quizzes</h2>
  <p>During blindfold chess, it can be challenging to maintain full awareness of the board. To help you improve your memory and board visualization, we've introduced Board Awareness Quizzes. These quizzes test your ability to identify critical checks, and captures without relying on visual aids.</p>
  <div className="screenshot-container">
    <img src={`${process.env.PUBLIC_URL}/help/awarenessQuiz.png`} alt="Blindfold Mode" className="screenshot-small" />
  </div>

  <p>Throughout the game, at random intervals you will be prompted with a quiz that asks you to find all potential checks or captures from your current position for both you and your opponent. These quizzes are designed to sharpen your ability to calculate moves and recognize threats, enhancing your ability to play blindfold chess.</p>
  <div className="screenshot-container">
    <img src={`${process.env.PUBLIC_URL}/help/quizDialogue.png`} alt="Blindfold Mode" className="screenshot-small" />
  </div>

  <h3>How the Quiz Works</h3>
  <p>
    When a quiz is triggered, a dialogue box will appear, prompting you to draw arrows to indicate your answers or input moves in algebraic notation in the move input container. The goal is to find all possible checks and captures, both for yourself and your opponent. Even the bad ones!
  </p>

  <ul>
    <li><strong>Checks:</strong> Can you spot the squares where you can place your opponent's king in check and where your king may be checked?</li>
    <li><strong>Captures:</strong> Can you identify which pieces can be captured on your move and which pieces your opponent can capture as if it were his move?</li>
  </ul>

  <p>As you progress through the quiz, you will receive feedback on whether your answers are correct. If you're struggling, you can click the "Give Up" button to reveal all correct solutions.</p>
  <div className="screenshot-container">
    <img src={`${process.env.PUBLIC_URL}/help/quizSolution.png`} alt="Blindfold Mode" className="screenshot-small" />
  </div>

  <h3>Visual Feedback</h3>
  <p>After completing a quiz or giving up, arrows will appear on the board, showing the correct checks and captures, helping you learn from the quiz. You can use these quizzes to enhance your visualization skills and improve your blindfold play over time.</p>
  <div className="screenshot-container">
    <img src={`${process.env.PUBLIC_URL}/help/quizSolutionBoard.png`} alt="Board Awareness Quiz" className="screenshot" />
  </div>
  <p>Quizzes are integrated seamlessly into your blindfold games, allowing you to test your awareness without disrupting gameplay.</p>

</section>

      <section className="why-train-blindfold">
        <h2>Why Train Blindfold Chess?</h2>
        <p>Training in blindfold chess is one of the most effective ways to improve your board vision and calculation skills. By gradually reducing the amount of visual information available, you can develop a stronger ability to visualize positions, calculate ahead, and evaluate positions without relying on seeing the pieces physically.</p>
        <ul>
          <li><strong>Improved Visualization:</strong> You’ll sharpen your ability to visualize complex board states in your mind.</li>
          <li><strong>Enhanced Memory:</strong> By practicing blindfold chess, you'll improve your ability to remember the position of each piece even without seeing the board.</li>
          <li><strong>Better Calculation:</strong> Training at higher blindfold levels helps you analyze moves and variations more deeply without external aids.</li>
        </ul>
      </section>

      <section className="user-controls">
        <h2>How to Use the Blindfold Levels</h2>
        <p>When setting up a blindfold game, you can choose from the six levels described above. Here’s how to get started:</p>
        <ul>
          <li><strong>Step 1:</strong> Select your preferred blindfold level. You can start at the "Normal" level and work your way up as your skills improve.</li>
          <li><strong>Step 2:</strong> Begin the game and focus on visualizing the board in your mind as moves are made.</li>
          <li><strong>Step 3:</strong> As you improve, increase the difficulty by selecting higher blindfold levels, where less visual information is shown.</li>
        </ul>
      </section>

      <section className="further-help">
        <h2>Need More Help?</h2>
        <p>For further assistance or any questions, check the <a href="/help">main help page</a> or <a href="/contact">contact us</a> for additional resources.</p>
      </section>
    </div>
  );
};

export default PlayBlindfoldHelp;
