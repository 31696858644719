  export const moveTrainerRide = [
    {
      disableBeacon: true,
      placement: 'center',
      target: '.file-structure-sidebar',
      title: 'Welcome to Opening Trainer!',
      content: 'On this page you are able to develop and train your very own opening repertoire. Getting started is simple.',
    },
    {
      disableBeacon: true,
      placement: 'right-start',
      target: '.folder-icon',
      content: 'Let\'s start by creating a folder to organize all of your opening variations. What opening do you want to start with?',
    },
  ];

  export const moveTrainerRide2 = [
    {
      disableBeacon: true,
      placement: 'right-start',
      target: '.plus-icon',
      content: 'Once you have selected a folder you will be able to add lines using this button.',
    },
    {
      disableBeacon: true,
      placement: 'left',
      target: '.chessboard-container',
      content: 'You will input moves here on the board or you can add variations by pasting a pgn.',
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.note-container',
      content: 'You can add notes and observations here to help you remember and understand your opening.',
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.bottom-buttons',
      content: 'Once you have your line ready to save click a floppy disk save button that will appear here to add it to your folder.',
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.test-button',
      content: 'With variations added to a folder you can click "TRAIN" to start practicing your repertoire!',
    },
    {
      disableBeacon: true,
      placement: 'center',
      target: '.file-structure-sidebar',
      title: 'Good Luck!',
      content: 'Have fun building your game winning repertoires!',
    },
  ];

    export const findForkRide = [
        {
            disableBeacon: true,
            placement: 'center',
            target: '.game-layout', // CSS selector for the container
            content: 'Sharpen your ability to spot forks, helping you either execute or defend against them effectively in games.',
            title: 'Welcome to Find the Fork!',
        },
        {
            disableBeacon: true,
            target: '.chessboard-container',
            content: 'Simply click the square where the white piece should move to attack both of black\'s pieces.',
        },
        {
            disableBeacon: true,
            target: '.puzzle-button',
            content: 'Feel free to practice, but when you\'re ready hit "Start Game" to see how many puzzles you can solve in 60 seconds.',
        },
        {
            disableBeacon: true,
            target: '.leaderboard',
            placement: 'top',
            content: 'Here, you’ll see the all-time and monthly records. Compete to see your name here!',
        },
        {
            disableBeacon: true,
            placement: 'center',
            target: '.feedback-container',
            content: (
                <>
                    For any additional questions you can access the{' '}
                    <a href="/findthefork/help" target="_blank" rel="noopener noreferrer">
                        help page
                    </a>.
                </>
            ),
        },
    ];

    export const coordinateRide = [
        {
            disableBeacon: true,
            placement: 'center',
            target: '.game-layout',
            content: 'Improve your ability to recognize chessboard coordinates with this training game.',
            title: 'Welcome to the Coordinate Trainer!',
        },
        {
            disableBeacon: true,
            target: '.chessboard-container',
            content: 'Click the square that matches the coordinate given to you. Try to be as quick and accurate as possible!',
        },
        {
            disableBeacon: true,
            target: '.puzzle-button',
            content: 'You can practice freely. When you’re ready, click "Start Game" to begin a 60-second challenge. See how many you can get correct in the time frame.',
        },
        {
            disableBeacon: true,
            placement: 'top',
            target: '.leaderboard',
            content: 'Check out the leaderboard to see the top scores! Compete for both monthly and all-time records.',
        },
        {
            disableBeacon: true,
            placement: 'center',
            target: '.feedback-container',
            content: (
                <>
                    Need additional help? Visit the{' '}
                    <a href="/coordinatetrainer/help" target="_blank" rel="noopener noreferrer">
                        help page
                    </a>.
                </>
            ),
        },
    ];
