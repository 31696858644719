// PromotionModal.js
import React from 'react';

const PromotionModal = ({ onPromote, pieces, color }) => {
    return (
        <div className="promotion-modal">
            <div className="promotion-options">
                {pieces.map((piece) => (
                    <img key={piece}
                         src={`${process.env.PUBLIC_URL}/pieces/normal/${color}${piece.toUpperCase()}.png`}
                         alt={piece}
                         onClick={() => onPromote(piece)} // This also closes the modal now
                    />
                ))}
            </div>
            {/* Removed the Cancel button as the modal closes automatically upon selecting a piece */}
        </div>
    );
};

export default PromotionModal;
