import React, { useState, useEffect, useCallback, memo, useMemo } from 'react';
import { getFirestore, collection, query, orderBy, limit, getDocs } from 'firebase/firestore';

const db = getFirestore();

const Leaderboard = memo(({ leaderboardType, toggleLeaderboardView, pathBase }) => {
    const [scores, setScores] = useState([]);

    const getCurrentMonthPath = useCallback(() => {
        const currentMonth = new Date().toLocaleString('default', { month: 'long' });
        const currentYear = new Date().getFullYear();
        return `${pathBase}/${currentYear}-${currentMonth}`;
    }, [pathBase]);

    const leaderboardPath = useMemo(() => {
        return leaderboardType === 'monthly' ? getCurrentMonthPath() : `${pathBase}/allTime`;
    }, [leaderboardType, getCurrentMonthPath]);

    const fetchScores = useCallback(async () => {
        const leaderboardData = await fetchLeaderboard(leaderboardPath);
        setScores(leaderboardData);
    }, [leaderboardPath]);

    useEffect(() => {
        fetchScores();
    }, [fetchScores]);

    const fetchLeaderboard = async (path) => {
        const leaderboardQuery = query(collection(db, path), orderBy('score', 'desc'), limit(5));
        const snapshot = await getDocs(leaderboardQuery);
        return snapshot.docs.map(doc => doc.data());
    };

    return (
        <div className="leaderboard">
            <div className="leaderboard-header">
                <h3>{leaderboardType === 'monthly' ? 'Monthly' : 'All-Time'} Leaderboard</h3>
                <button onClick={toggleLeaderboardView} className="leaderboard-toggle-button">
                    <span>{'>'}</span>
                </button>
            </div>
            <ul className="leaderboard-list">
                {scores.map((score, index) => (
                    <li key={index} className={`leaderboard-item rank-${index + 1}`}>
                        <span className="rank">{index + 1}.</span>
                        <span className="username">{score.username || 'Anonymous'}</span>
                        <span className="score">{score.score}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
});

export default Leaderboard;
