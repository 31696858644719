// JoyrideWrapper.tsx (or .jsx)
import React from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';

interface JoyrideWrapperProps {
    steps: Step[];
    run: boolean;
    onFinish: () => void;
}

const JoyrideWrapper: React.FC<JoyrideWrapperProps> = ({ steps, run, onFinish }) => {
    const handleJoyrideCallback = (data: CallBackProps) => {
        if (data.status === 'finished' || data.status === 'skipped') {
            onFinish(); // Callback to handle when tour finishes
        }
    };

// Grab the root element and access the CSS variables
const rootStyles = getComputedStyle(document.documentElement);

// Access each variable with the `getPropertyValue` method
const primaryColor = rootStyles.getPropertyValue('--primary-color').trim();
const secondaryColor = rootStyles.getPropertyValue('--secondary-color').trim();
const highlightColor = rootStyles.getPropertyValue('--highlight-color').trim();
const textColor1 = rootStyles.getPropertyValue('--text-color1').trim();
const backgroundColor = rootStyles.getPropertyValue('--background-color').trim();

    return (
        <Joyride
            steps={steps}
            run={run}
            continuous
            showProgress
            showSkipButton
            hideCloseButton
            callback={handleJoyrideCallback}
            styles={{
              options: {
                primaryColor: highlightColor,
                textColor: textColor1,
                zIndex: 1000,
              },
              tooltipTitle: {
                color: primaryColor,
                fontSize: '1.25rem',
                fontWeight: 'bold',
              },
              tooltipContent: {
                fontSize: '1rem',
                color: textColor1,
              },
              buttonNext: {
                backgroundColor: highlightColor,
                color: backgroundColor,
                borderRadius: '4px',
                padding: '8px 16px',
              },
              buttonSkip: {
                color: textColor1,
                fontSize: '0.9rem',
              },
            }}
        />
    );
};

export default JoyrideWrapper;
