import React from 'react';
import '../../styles/help.css'; // Ensure the path matches your folder structure
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Import the scroll enabler

const AlgebraicNotation = () => {
  return (
    <div className="help-container">
      {/* Enable scrolling on this page */}
      <EnableScrollOnPage />

      <h1>Chess Algebraic Notation</h1>

      <section className="intro-section">
        <p>
          Chess algebraic notation is the most widely used system for recording and reading chess games. It uses a combination of letters and numbers to describe moves and board positions, making it easy to study and analyze games, whether you're a beginner or a seasoned player. This guide will explain how to read and write chess notation and highlight any anomalies or special cases.
        </p>
      </section>

      <section className="notation-basics">
        <h2>Basics of Algebraic Notation</h2>
        <p>Each move in chess is represented by the piece being moved and the square it moves to, based on the following:</p>
        <ul>
          <li><strong>Files:</strong> The columns of the chessboard are labeled from 'a' to 'h'.</li>
          <li><strong>Ranks:</strong> The rows are labeled from '1' to '8'.</li>
          <li><strong>Pieces:</strong> Pieces are represented by uppercase letters:
            <ul>
              <li><strong>K</strong> for King</li>
              <li><strong>Q</strong> for Queen</li>
              <li><strong>R</strong> for Rook</li>
              <li><strong>B</strong> for Bishop</li>
              <li><strong>N</strong> for Knight (since 'K' is reserved for King)</li>
              <li><strong>Pawns</strong> are not represented by a letter; moves are indicated by the square they move to.</li>
            </ul>
          </li>
        </ul>
        <p>For example, <code>e4</code> means a pawn is moved to the square e4, and <code>Nf3</code> means a knight moves to f3.</p>
      </section>

      <section className="special-moves">
        <h2>Special Moves</h2>
        <h3>1. Castling</h3>
        <p>
          Castling is the only move that involves two pieces: the king and a rook. There are two types of castling:
        </p>
        <ul>
          <li><strong>Kingside Castling:</strong> <code>O-O</code>, where the king moves two squares toward the rook on the kingside, and the rook jumps over.</li>
          <li><strong>Queenside Castling:</strong> <code>O-O-O</code>, where the king moves two squares toward the rook on the queenside, and the rook jumps over.</li>
        </ul>

        <h3>2. Pawn Promotion</h3>
        <p>When a pawn reaches the 8th rank, it is promoted to another piece (typically a queen). This is written as <code>e8=Q</code> for a queen promotion or <code>d1=N</code> for a knight promotion.</p>

        <h3>3. En Passant</h3>
        <p>If a pawn moves two squares forward and lands next to an opposing pawn, that pawn can capture it as if it had only moved one square. This is called an "en passant" capture and is written similarly to a normal capture, e.g., <code>exd6</code>.</p>
      </section>

      <section className="capture-moves">
        <h2>Capturing Pieces</h2>
        <p>When a piece captures another, an "x" is inserted before the destination square. For example, <code>Rxf7</code> means a rook captures a piece on the f7 square, and <code>exd5</code> means a pawn from the 'e' file captures a piece on d5.</p>
      </section>

      <section className="disambiguation">
        <h2>Disambiguating Moves</h2>
        <p>Sometimes, more than one piece of the same type can move to the same square. To resolve this, the move is clarified:</p>
        <ul>
          <li>If two knights can move to d2, write the file, e.g., <code>Nbd2</code> for the knight on the 'b' file.</li>
          <li>If two pieces on the same file can move to the same square, use the rank, e.g., <code>N1d2</code>.</li>
        </ul>
      </section>

      <section className="check-checkmate">
        <h2>Check and Checkmate</h2>
        <p>
          When a move puts the opposing king in check, a "+" symbol is added after the move, e.g., <code>Qh5+</code>. If the move results in checkmate, use a "#" symbol, e.g., <code>Qh5#</code>.
        </p>
      </section>

      <section className="notations-example">
        <h2>Example Game</h2>
        <p>Here’s a sample game using algebraic notation:</p>
        <pre>
          1. e4 e5<br />
          2. Nf3 Nc6<br />
          3. Bb5 a6<br />
          4. Ba4 Nf6<br />
          5. O-O Be7<br />
          6. Re1 b5<br />
          7. Bb3 d6<br />
          8. c3 O-O<br />
          9. h3 Nb8<br />
          10. d4 Nbd7
        </pre>
        <p>This format records every move in the game, using algebraic notation to show both white and black's actions.</p>
      </section>

      <section className="further-help">
        <h2>Need More Help?</h2>
        <p>For further assistance or any questions, check the <a href="/help">main help page</a> or <a href="/contact">contact us</a> support for additional resources.</p>
      </section>
    </div>
  );
};

export default AlgebraicNotation;
