import { useState, useEffect, useRef } from 'react';

// Hook to detect when an element is in view
function useInView(threshold = 0.1) {
    const [isInView, setIsInView] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsInView(true);
                    observer.disconnect(); // Stop observing once it becomes visible
                }
            },
            {
                threshold: threshold, // How much of the element is in view before triggering
            }
        );

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => observer.disconnect();
    }, [threshold]);

    return [isInView, elementRef];
}

export default useInView;
