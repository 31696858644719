import React from 'react';
import { Link } from 'react-router-dom';
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Enable scroll behavior

const WelcomePage = () => {
  return (
    <div className="help-container">
      <EnableScrollOnPage />
      <h1>Welcome!</h1>
      <p>
        Welcome to Train Chess, your chess improvement platform. Train Chess is a web app developed to provide chess training resources that are optimized for improvement and are based on the science of learning and improvement. We have several features unique to our webpage. Try some of our exercises and see what they do for your chess elo.
      </p>

      {/* Unique Features Section */}
      <section className="unique-features">
        <h2>What Makes Us Unique?</h2>
        <p>
          At Train Chess, we have compiled innovative chess training techniques. Some of these include:
        </p>
        <ul>
          <li><strong>Our Unique Rating System:</strong> We use a more optimal rating system for improvement. Backed by research, the 85% rule will help you improve in areas that are most in reach but have been leading to those "dumb mistakes". <Link to="/ratingmetrics">Learn More</Link></li>
          <li><strong>Blindfold Chess Progression:</strong> Improve your visualization of the board with blindfold chess. We understand that blindfold chess is extremely difficult, which is why we developed a 6 step progression to help you gradually work into full blindfold chess. Test and improve your board awareness during the game with periodic quizzes on the position. <Link to="/play">Blindfold Chess</Link></li>
          <li><strong>Calculation Practice:</strong> Our calculation puzzles allow you to work on evaluating positions that haven't yet been reached on the board, a crucial skill in chess. In the never ending effort to eliminate your worst blunders this exercise is a must try. <Link to="/calculationpuzzles">Calculation Puzzles</Link></li>
          <li><strong>More:</strong> We have several more exercises to explore you can find more about <Link to="/help">here</Link> and are working on developing more. For suggestions or feedback <Link to="/contact">contact us</Link></li>
        </ul>
      </section>

      {/* Further Resources and Contact Section */}
      <section className="further-help">
        <h2>More Resources</h2>
        <p>
          Need more help or want to learn more about TrainChess? Visit our <Link to="/about">About Page</Link> for more information, or feel free to <Link to="/contact">Contact Us</Link> with any questions.
        </p>
      </section>
    </div>
  );
};

export default WelcomePage;
