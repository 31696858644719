import { useContext, useEffect } from 'react';
import { ThemesContext } from './ThemesContext';

const ThemeApplier = () => {
  const { theme } = useContext(ThemesContext);

const toKebabCase = (str) => {
  // Check if the string already starts with '--'
  if (str.startsWith('--')) {
    return str; // It's already a CSS variable name, return as is
  }
  return '--' + str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
};

  useEffect(() => {
    const root = document.documentElement;

    if (theme) {
      Object.entries(theme).forEach(([key, value]) => {
        const cssVarName = toKebabCase(key); // Convert key to CSS variable name
        root.style.setProperty(cssVarName, value); // Set the CSS variable on :root
      });
    } else {
      console.error('No theme object provided.');
    }
  }, [theme]); // Re-apply when theme changes

  return null; // This component does not render anything
};

export default ThemeApplier;
