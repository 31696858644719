import React, { useEffect, useState } from 'react';
import ChessboardPreview from './ChessboardPreview';

const BlindfoldChessboardModal = ({ fen, playerColor, onClose }) => {
  const [boardWidth, setBoardWidth] = useState(200);

  useEffect(() => {
    const updateBoardWidth = () => {
      const windowWidth = window.innerWidth;
      const maxWidth = 600;
      const newBoardWidth = Math.min(windowWidth * 0.8, maxWidth); // 80% of window width, max 600px
      setBoardWidth(newBoardWidth);
    };

    // Update board size on load and window resize
    updateBoardWidth();
    window.addEventListener('resize', updateBoardWidth);

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', updateBoardWidth);
  }, []);

  return (
    <div className="overlay">
      <div className="modal">
        <button className="close-button" onClick={onClose}>×</button>
        <ChessboardPreview
          fen={fen}
          playerColor={playerColor}
          className="chessboard-preview"
          boardWidth={boardWidth}
        />
      </div>
    </div>
  );
};

export default BlindfoldChessboardModal;
