import React, { useEffect } from 'react';
import lottie from 'lottie-web';

const KnightHopLoadingScreen = () => {
  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: document.getElementById('knight-hop-animation'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: `${process.env.PUBLIC_URL}/knightHop.json` // Path to your Lottie file
    });

    return () => {
      animation.destroy();
    };
  }, []);

  return (
    <div className="loading-overlay">
      <div id="knight-hop-animation" className="knight-hop-animation"></div>
    </div>
  );
};

export default KnightHopLoadingScreen;
