// src/components/Countdown.js
import React, { useState, useEffect } from 'react';

const Countdown = ({ onCountdownComplete }) => {
  const [count, setCount] = useState(3);

  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => setCount(count - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      onCountdownComplete(); // Trigger game start once countdown is complete
    }
  }, [count, onCountdownComplete]);

  return (
    <div className="countdown-overlay">
      {count > 0 && <div className="countdown-number">{count}</div>}
    </div>
  );
};

export default Countdown;
