import React from 'react';
import '../../styles/help.css'; // Ensure the path matches your folder structure
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Import the scroll enabler

const CoordinateTrainerHelp = () => {
  return (
    <div className="help-container">
      {/* Enable scrolling on this page */}
      <EnableScrollOnPage />

      <h1>Coordinate Trainer</h1>

      <section className="how-to-solve">
        <h2>What is the Coordinate Trainer?</h2>
        <p><a href="/coordinatetrainer">Coordinate trainer</a> helps you practice identifying squares on the chessboard using standard chess coordinates (e.g., e4, g7, etc.). In any blindfold play or understanding <a href="/algebraicnotation">algebraic notation</a>, having the ability to quickly and accurately recognize squares based on their coordinates very helpful.</p>
        <div className="screenshot-container">
          <p><strong>Chessboard Coordinates:</strong></p>
          <img src={`${process.env.PUBLIC_URL}/help/coordinates.jpg`} alt="Coordinates Example" className="screenshot" />
        </div>
        <p>When you start a session, the trainer will give you a <strong>target square</strong> to identify on the board. Your task is to click on the correct square. Be sure to notice which side of the board you are looking from. If you are looking from black's perspective coordinates will be reversed like this:</p>
        <div className="screenshot-container">
          <p><strong>Chessboard Coordinates as Black:</strong></p>
          <img
            src={`${process.env.PUBLIC_URL}/help/coordinates.jpg`}
            alt="Coordinates Example"
            className="screenshot"
            style={{ transform: 'rotate(180deg)' }}
          />
        </div>


        <p>Let’s look at an example from black's perspective. We are prompted to find the c1 square so we click here:</p>
        <div className="screenshot-container">
          <p><strong>Selecting the Correct Square</strong></p>
          <img src={`${process.env.PUBLIC_URL}/help/selectSquare.png`} alt="Select Square Example" className="screenshot" />
        </div>

        <p>If you select the correct square, the board will flash <strong>green</strong> and move on to the next square. If you select an incorrect square, the board will flash <strong>red</strong> to indicate an error, allowing you to try again.</p>
      </section>

      <section className="game-modes">
        <h2>Game Mode vs. Free Practice</h2>
        <p>There are two ways to use the Coordinate Trainer: in <strong>Game Mode</strong> and in <strong>Free Practice Mode</strong>.</p>

        <h3>Free Practice Mode</h3>
        <p>In Free Practice Mode, you can click the squares freely without worrying about time or scoring. Simply follow the instructions and find the squares as the trainer provides them. This is great for general practcie and building confidence.</p>

        <h3>Game Mode</h3>
        <p>If you click <strong>Start Game</strong> you’ll have 15 seconds to identify as many correct squares as possible. Each correct answer will increment your score, and your score will be displayed at the end of the session. If you break your best score, you’ll be notified!</p>

      </section>

      <section className="benefits-section">
        <h2>Why Use the Coordinate Trainer?</h2>
        <ul>
          <li><strong>Improved Board Visualization:</strong> The Coordinate Trainer will improve your ability to visualize and mentally map the chessboard, helping you think ahead in games.</li>
          <li><strong>Increased Speed:</strong> With regular practice, you’ll be able to identify squares more quickly and confidently, improving your ability to read notation and play chess blindfolded.</li>
        </ul>
      </section>

      <section className="further-help">
        <h2>Need More Help?</h2>
        <p>For further assistance or any questions, check the <a href="/help">main help page</a> or <a href="/contact">contact us</a> for additional resources.</p>
      </section>
    </div>
  );
};

export default CoordinateTrainerHelp;
